import React from 'react';

const InputComponent = ({
  role,
  onClick,
  onChange,
  type,
  disabled,
  className,
  children,
  block,
  outline,
  placeholder,
  required,
  value,
}) => (
  <input
    type={type}
    role={role}
    disabled={disabled}
    onClick={onClick}
    onChange={onChange}
    className={className}
    block={block}
    outline={outline}
    placeholder={placeholder}
    required={required}
    value={value}
    autocomplete="new-password"
  />
);

export default InputComponent;
