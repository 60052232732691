import { useEffect, useState } from "react";
import { httpGet, httpPatch, httpPost } from "../../../action/http";

const useCoupon = () => {
  const [coupons, setCoupons] = useState([]);
  const getCoupons = async () => {
    const res = await httpGet("/coupon/all_coupons");
    if (res.success) setCoupons(res.payload);
  };
  const createCoupon = async (data) => {
    const res = await httpPost("/coupon/create_coupon", data);
    if (res.success) {
      getCoupons();
      return res;
    }
  };

  const expireCoupon = async (id) => {
    const res = await httpPatch("/coupon/expire_coupon", { id });
    if (res.success) {
      getCoupons();
      return res;
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  return {
    coupons,
    createCoupon,
    expireCoupon,
  };
};

export default useCoupon;
