import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import validator from "validator";
import ImageUploader from "react-images-upload";
import Layout from "../../shared/Layout";
import Button from "../../shared/Button";
import "./index.css";
import { httpPost, httpGet, httpPostData } from "../../../action/http";
import removeEmptyString from "../../../utils/removeEmptyString";
import { hideLoader, showLoader } from "../../../utils/loader";

const Create = (props) => {
  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validator.isEmpty(category.name)) {
      swal("Oops!", "Category title cannot be empty", "error");
      return;
    }
    const data = {
      name: category.name,
    };
    const res = await httpPost("/create_category", data);
    if (res.code === 201) {
      swal("Done", res.message, "success").then(() =>
        props.history.push("/categories")
      );
    }
    hideLoader();
  };

  const [category, setCategory] = useState({
    name: "",
    des: "",
  });
  const handleChange = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout
      RouteUserLayout={props.history}
      page="category"
      activepage="keepOpenEvents"
    >
      <div style={{ borderRadius: "10px" }} id="category-wraper">
        <div className="center-category-form ">
          <h1>Create Category</h1>
          <form className="category-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Title <span className="impt-i">*</span>
              </label>
              <input
                onChange={(e) => handleChange(e)}
                name="name"
                type="text"
                className="form-control"
                id="title"
                value={category.name}
                placeholder="Enter Title"
                required={true}
              />
            </div>

            <div className="w-100 text-center mt-5">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Create Category
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
