import React, { useState, useEffect } from "react";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../../helpers/formatDate";
import Switch from "react-switch";
const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

const Udata = [
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
];

const handleCategoryChange = () => {
  alert("hello");
};

export default function UserTransationTable({ vendorTeam }) {
  useEffect(() => {
    console.log(vendorTeam);
  }, []);
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Full Name", field: "name" },
          { title: "Email", field: "email" },
          // { title: "Phone Number", field: "phone" },
          { title: "Role ", field: "Status" },
        ]}
        data={vendorTeam?.company?.user.map((data) => {
          return {
            name:
              data?.role?.name == "company owner"
                ? "Company Owner"
                : `${data.firstName} ${data.firstName}`,
            email: data?.email,
            // phone: data?.phoneNumber,
            Status: data?.role?.name,
          };
        })}
        title="Company Members"
      />
    </div>
  );
}
