import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpPatch } from "../../../action/http";
import swal from "sweetalert";
import DataTable from "../../shared/DataTable";
import FormatDate from "../../../helpers/formatDate";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";
import { hideLoader, showLoader } from "../../../utils/loader";

export const SubscriptionTable = ({
  getSubscriptions,
  approveSubscription,
  getAllSubscription,
}) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const [showModal, setShowModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const data = getSubscriptions.map((data, index) => ({
    PublicationName: data?.publication?.name,
    companyName: data?.publication?.company?.businessName,
    price: `N${data?.price}`,
    charge: `N${data?.serviceCharge}`,
    publicationDays: data?.publishDays?.map((day) => `${day}, `),
    status:
      data?.status === null ? "Undefined" : CapitalizeFirstLetter(data?.status),
    dateApplied: FormatDate(data.createdAt),
    action: (
      <div>
        <div>
          <select
            className="useractionSelect"
            name=""
            onChange={(e) => {
              approveSubscription(e.target.value, data.id);
            }}
          >
            <option value="">Select Action</option>
            {data.status === "active" && (
              <option value="inactive">Deactivate</option>
            )}
            {data.status === "pending" &&
              ["active", "reject"].map((item) => (
                <option value={item}>
                  {item === "active" ? "Activate" : "Reject"}
                </option>
              ))}
            {data.status === "inactive" && (
              <option value="active">Activate</option>
            )}
          </select>
        </div>
      </div>
      // <div className="d-flex">
      //   <button
      //     className="btn btn-sm button-primary text-white mr-3"
      //     disabled={data.status === "active" ? true : false}
      //     style={data.status === "active" ? { opacity: "0.5" } : {}}
      //     onClick={() => approveSubscription("active", data.id)}
      //   >
      //     {data.status === "active" ? "Approved" : "Approve"}
      //   </button>
      //   <button
      //     disabled={data.status === 'active' ? }
      //     className="btn btn-sm btn-danger"
      //     onClick={() => approveSubscription("reject", data.id)}
      //   >
      //     Reject
      //   </button>
      // </div>
    ),
  }));

  return (
    <div className="table-responsive">
      <DataTable
        title="Subscriptions"
        columns={[
          { title: "Publication Name", field: "PublicationName" },
          { title: "Company Name", field: "companyName" },
          { title: "Price", field: "price" },
          { title: "Service Charge", field: "charge" },
          { title: "Days", field: "publicationDays" },
          { title: "Status", field: "status" },
          { title: "Applied On", field: "dateApplied" },
          { title: "Action", field: "action" },
        ]}
        data={data}
      />
    </div>
  );
};
