import React, { Component } from "react";

export default function ReportCards(props) {
  function formatCurrency(amount, locale = "en-NG", currency = "NGN") {
    return amount.toLocaleString(locale, {
      style: "currency",
      currency: currency,
    });
  }
  const {
    // payout,
    tnsProfit,
    totalUserCurrentWalletBalance,
    totalVendorCurrentWalletBalance,
    withdrawalAmount,
    totalAmountMade,
    // fundWalletTransactions,
  } = props.stats;
  return (
    <div>
      <div className="grp-overview">
        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Total Amount Made
          </h1>
          <p>{formatCurrency(totalAmountMade)}</p>
        </div>

        {/* <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Available Balance
            <br />
            (for withdrawal)
          </h1>
          <p>
            {formatCurrency(Number(tnsProfit) - Number(withdrawalAmount) )}
          </p>
        </div> */}

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i> TNS Balance
          </h1>
          <p>{formatCurrency(Number(tnsProfit) - Number(withdrawalAmount) ) }</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i> Vendors Balance
          </h1>
          <p>{formatCurrency(totalVendorCurrentWalletBalance)}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i>Users Wallet Balance
          </h1>
          <p>{formatCurrency(totalUserCurrentWalletBalance)}</p>
        </div>
      </div>
    </div>
  );
}
