import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { httpPatch } from '../../../action/http';
import swal from 'sweetalert';
import DataTable from '../../shared/DataTable';
import FormatDate from '../../../helpers/formatDate';
import CapitalizeFirstLetter from '../../../helpers/capitalLizeFirstLetter';
import ServiveCharge from './ServiceCharge';
import { hideLoader, showLoader } from '../../../utils/loader';

export const SubscriptionTable = ({ getSubscriptions, approveSubscription, getAllSubscription }) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const [showModal, setShowModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    showLoader();

    const res = await httpPatch(`/admin/con_update_charge?id=${subscriptions.id}&serviceCharge=${subscriptions.serviceCharge}`);
    if (res.code === 200) {
      swal('Success', res.message, 'success');
      hideLoader();
      setLoading(false);
      setShowModal(!showModal);
      getAllSubscription();
    } else {
      swal('Error', res.message, 'error');
      setLoading(false);
      hideLoader();
    }
  };
  const data = getSubscriptions.map((data, index) => ({
    name: data?.publication?.name,
    companyName: data?.publication?.company?.businessName,
    price: data?.price,
    serviceCharge: (
      <div className="d-flex">
        {' '}
        {data?.serviceCharge}{' '}
        <i
          onClick={() => {
            setShowModal(!showModal);
            setSubscriptions({
              ...subscriptions,
              id: data.id,
              serviceCharge: data.serviceCharge,
            });
          }}
          class="fas fa-edit"
          style={{
            marginLeft: '10px',
          }}
        ></i>
      </div>
    ),
    status: data?.status === null ? 'Undefined' : CapitalizeFirstLetter(data?.status),
    createdAt: FormatDate(data.createdAt),
    action: (
      <div>
        <div>
          <select
            className="useractionSelect"
            name=""
            onChange={(e) => {
              approveSubscription(e.target.value, data.id);
            }}
          >
            <option value="">Select Action</option>
            {data.status === 'active' && <option value="inactive">Deactivate</option>}
            {data.status === 'inactive' && <option value="active">Re-activate</option>}
            {data.status === 'pending' &&
              ['active', 'reject'].map((item) => <option value={item}>{item === 'active' ? 'Activate' : 'Reject'}</option>)}
          </select>
        </div>
      </div>
      // <div className="d-flex">
      //   <button
      //     className="btn btn-sm button-primary text-white mr-3"
      //     disabled={data.status === "active" ? true : false}
      //     style={data.status === "active" ? { opacity: "0.5" } : {}}
      //     onClick={() => approveSubscription("active", data.id)}
      //   >
      //     {data.status === "active" ? "Approved" : "Approve"}
      //   </button>
      //   <button
      //     disabled={data.status === 'active' ? }
      //     className="btn btn-sm btn-danger"
      //     onClick={() => approveSubscription("reject", data.id)}
      //   >
      //     Reject
      //   </button>
      // </div>
    ),
  }));

  return (
    <div className="table-responsive">
      <DataTable
        title="Subscriptions"
        columns={[
          { title: 'Publication Name', field: 'name' },
          { title: 'Company Name', field: 'companyName' },
          { title: 'Price', field: 'price' },
          { title: 'Service Charge', field: 'serviceCharge' },
          { title: 'Status', field: 'status' },
          { title: 'Applied On', field: 'createdAt' },
          { title: 'Action', field: 'action' },
        ]}
        data={data}
      />

      <ServiveCharge
        showModal={showModal}
        hideModal={setShowModal}
        handleSubmit={handleSubmit}
        subscriptions={subscriptions}
        setSubscriptions={setSubscriptions}
        loading={loading}
      />
    </div>
  );
};
