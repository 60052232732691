import React, { Component } from "react";
import "./index.css";

export const Overview = (props) => {
  const {
    newUsers,
    newVendors,
    publicationCount,
    successfulTransaction,
    totalActiveUsers,
    transactions,
    userCount,
    vendorCount,
  } = props.stats;
  return (
    <div>
      <div className="grp-overview">
        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Total Transactions
          </h1>
          <p>{transactions}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-users" aria-hidden="true"></i>Successful
            Transaction
          </h1>
          <p>{successfulTransaction}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fa fa-calendar" aria-hidden="true"></i> Total Vendors
          </h1>
          <p>{vendorCount}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-briefcase"></i> New Vendors
          </h1>
          <p>{newVendors}</p>
          <span
            className=""
            style={{ marginTop: "-16px", fontSize: "13px", fontWeight: "400" }}
          >
            {" "}
            In the last one month
          </span>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            Total Users
          </h1>

          <p>{userCount}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            New Users
          </h1>

          <p>{newUsers}</p>

          <span
            className=""
            style={{ marginTop: "-16px", fontSize: "13px", fontWeight: "400" }}
          >
            {" "}
            In the last one month
          </span>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            Total Active Users
          </h1>

          <p>{totalActiveUsers}</p>
        </div>

        <div className="grp-overview1">
          <h1>
            {" "}
            <i className="fas fa-user-friends"></i>
            Publication Count
          </h1>

          <p>{publicationCount}</p>
        </div>
      </div>
    </div>
  );
};
