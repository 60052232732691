import React, { useState } from "react";
import icons from "./../pages/table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../helpers/formatDate";
import Switch from "react-switch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

const Udata = [
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
  {
    name: "Okeke Andrew",
    email: "JohnOkes@gmail.com",
    phone: "08954567733",
    status: "Approved",
  },
];

const handleCategoryChange = () => {
  alert("hello");
};

const info = [
  {
    publicationName: "Punch Newspaper ",
    title: "Live and win",
    body: "Win  recharge cards by ...",
    status: "Closed",
    date: "Dec 4, 2019 21:42",
  },
  {
    publicationName: "Punch Newspaper ",
    title: "Live and win",
    body: "Win  recharge cards by ...",
    status: "Closed",
    date: "Dec 4, 2019 21:42",
  },

  {
    publicationName: "Punch Newspaper ",
    title: "Live and win",
    body: "Win  recharge cards by ...",
    status: "Closed",
    date: "Dec 4, 2019 21:42",
  },

  {
    publicationName: "Punch Newspaper ",
    title: "Live and win",
    body: "Win  recharge cards by ...",
    status: "Closed",
    date: "Dec 4, 2019 21:42",
  },
  {
    publicationName: "Punch Newspaper ",
    title: "Live and win",
    body: "Win  recharge cards by ...",
    status: "Closed",
    date: "Dec 4, 2019 21:42",
  },
];

export default function UserTransationTable({
  userNotification,
  deleteMsg,
  getEditData,
}) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          // { title: "Publication Name", field: "publicationName" },
          { title: "Title", field: "title" },
          { title: "Msg Body", field: "body" },
          { title: "Status ", field: "status" },
          {
            title: "Date",
            field: "date",
          },
          {
            title: "Actions",
            field: "actions",
          },
        ]}
        data={userNotification?.map((data) => {
          return {
            // publicationName: data?.publicationName,
            title: data?.title,
            body: data?.body,
            status: data?.status,
            date: dateFormater(data?.updatedAt),
            actions: (
              <div style={{ display: "flex" }}>
                {" "}
                <div
                  className="edit"
                  style={{ marginRight: "20px", cursor: "pointer" }}
                  onClick={() => getEditData(data, "user")}
                >
                  <EditIcon />
                </div>
                <div
                  className="delete"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteMsg(data.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            ),
          };
        })}
        title="User Messages"
      />
    </div>
  );
}
