import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { MessageTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import Create from "./Create";
import { hideLoader, showLoader } from "../../../utils/loader";
import { socket } from "../../shared/Layout/Navbar";
import swal from "sweetalert";

const Index = (props) => {
  const [messages, setMessages] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    setLoading(false);
    getAllMessages();
    //console.log(socket);
  }, []);

  const getAllMessages = async () => {
    try {
      const res = await httpGet("/auth/all_messages");
      if (res.code === 200) {
        setMessages(res.data);
      }
    } catch (error) {
      return;
    }
  };

  const handleSubmit = async () => {
    showLoader();
    try {
      console.log(data);
      const msg = {
        to: data.type,
        message: data.description,
      };
      console.log(socket);
      socket.emit(`admin_send_notifications`, msg, (msg) => {
        hideLoader();
        console.log("sent");
      });
      getAllMessages();
      swal("Yepp!", "Message sent");
      hideLoader();
    } catch (error) {
      hideLoader();
      return;
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="messages"
        activepage="keepOpenGroup"
      >
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <div className="table-wrap">
            <div>
              <Create
                data={data}
                setData={setData}
                handleSubmit={handleSubmit}
              />
            </div>

            {/* <GroupsTable getGroup={getGroup}/> */}
            <MessageTable
              getMessages={messages || []}
              GetEditDataModals={GetEditDataModals}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
    </div>
  );
};

export default Index;
