import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { WithdrawRequestsTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import swal from "sweetalert";
import { httpPatch } from "../../../action/http";
import { hideLoader, showLoader } from "../../../utils/loader";

const Index = (props) => {
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getWithdrawRequest();
  }, []);

  const getWithdrawRequest = async () => {
    try {
      const res = await httpGet("/account/withdraw_wallet_list");
      if (res.code === 200) {
        console.log("res.data.paymentHistory", res.data.paymentHistory);
        setWithdrawRequests(res.data.paymentHistory);
        setLoading(false);
      }
    } catch (error) {
      return;
    }
  };
  const confirmWithdrawRequest = async (payload) => {
    showLoader();
    try {
      const res = await httpPost(`/withdraw_wallet_confirm`, payload);
      if (res?.code === 200) {
        setWithdrawRequests(
          withdrawRequests.filter(
            (data) => data.reference !== payload.reference
          )
        );
        swal("Confirmed!", "Withdraw request confirmed", "success");
        hideLoader(false);
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const confirmAction = async (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you wish to perform this action?",
      icon: "warning",
      dangerMode: true,
    }).then((willConfirm) => {
      if (willConfirm) {
        confirmWithdrawRequest(data);
        // swal("Deleted!", "Your imaginary file has been deleted!", "success");
      } else {
      }
    });
  };
  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="withdraw_request"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          <WithdrawRequestsTable
            getRequests={withdrawRequests || []}
            confirmAction={confirmAction}
          />
        </div>
      </Layout>
      <ConfirmModal />
    </div>
  );
};

export default Index;
