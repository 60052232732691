import React, { useState } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import { httpPost } from '../../../action/http';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { NotificationManager } from 'react-notifications';
import logo from '../../../assets/image/logo1.png';

const Index = (props) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validator.isEmpty(email)) {
      swal('error', 'Email cannot be empty', 'Error');
      return;
    }
    const data = {
      email,
    };
    const res = await httpPost('/auth/user/forgot_password', data);
    if (res.code === 200) {
      swal('Done', res.message, 'success');
      setLoading(false);
    } else {
      swal('Error', res.message, 'error');
      console.log(res);
      setLoading(false);
    }
  };
  return (
    <div className="row h-100">
      <div className="col-md-6 mx-auto my-auto h-50 text-center p-0">
        <img src={logo} alt="logo" />
        <h4 className="mt-3">Forgot Password</h4>
        <form autocomplete="off">
          <div className="p-xs-5">
            <Input
              type="text"
              placeholder="Email"
              className="form-control col-md-6 col-sm-6 mx-auto mt-5"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />

            <Button
              className="form-control col-md-6 col-sm-6 mx-auto mt-2"
              type="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
