import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import MaterialTable from "material-table";
import moment from "moment";
import * as _ from "lodash";
import {
  Paper,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import icons from "./icon";

export const SubscriptionTable = (props) => {
  function isDateInRange1(dateFrom, dateTo, dateToBeFiltered) {
    return dateFrom <= dateToBeFiltered && dateToBeFiltered <= dateTo;
  }
  function isDateInRange2(dateFrom, dateTo, dateToBeFiltered) {
    return dateFrom <= dateToBeFiltered && dateToBeFiltered <= dateTo;
  }

  const FilterNumericBetween = (props) => {
    const { columnDef, onFilterChanged } = props;

    return (
      <>
        <Accordion elevation={1} style={{ display: "block" }}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <FilterIcon /> <Typography>Filter Date</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <TextField
              style={{ marginTop: "-20px", marginBottom: "20px" }}
              type="date"
              value={
                _.get(columnDef, ["tableData", "filterValue", "dateFrom"]) || ""
              }
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateFrom = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
            <TextField
              type="date"
              value={
                _.get(columnDef, ["tableData", "filterValue", "dateTo"]) || ""
              }
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateTo = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          filtering: true,
          exportAllData: true,
          pageSizeOptions: [10, 20, 50, 100, 200],
          pageSize: 10,
        }}
        columns={[
          { title: "Transaction ID", field: "id", filtering: false },
          {
            title: "User Name",
            field: "name",
          },
          {
            title: "Email",
            field: "email",
          },
          {
            title: "Publication Name ",
            field: "publicationName",
          },
          {
            title: "Company Name ",
            field: "companyName",
          },
          {
            title: "Description",
            field: "message",
            filtering: false,
          },
          {
            title: "Amount",
            field: "amount",
            filtering: false,
          },
          {
            title: "Status",
            field: "status",
            lookup: {
              active: "active",
              pending: "pending",
            },
          },
          {
            title: "Subscription type",
            field: "subType",
            lookup: {
              monthly: "monthly",
              weekly: "weekly",
              one_off: "one-off",
              quarterly: "quarterly",
              annually: "annually",
              biannually: "biannually",
            },
          },

          {
            title: "Payment Date",
            field: "nextPaymentDate",
            filterComponent: FilterNumericBetween,
            customFilterAndSearch: (filter, rowData) =>
              isDateInRange1(
                filter.dateFrom,
                filter.dateTo,
                rowData.PaymentDate
              ),
          },
          {
            title: "Next Payment Date",
            field: "nextPaymentDate",
            filterComponent: FilterNumericBetween,
            customFilterAndSearch: (filter, rowData) =>
              isDateInRange2(
                filter.dateFrom,
                filter.dateTo,
                rowData.nextPaymentDate
              ),
          },

          {
            title: "Transaction Status",
            field: "paymentStatus",
            lookup: {
              successful: "successful",
              pending: "pending",
              failed: "failed",
            },
          },
          { title: "Action", field: "action", filtering: false },
        ]}
        data={
          props?.getSubscriptions?.length == 0
            ? []
            : props?.getSubscriptions?.map((data) => {
                return {
                  id: data?.paymentHistory[0]?.id,
                  name: `${data?.user?.firstName}  ${data?.user?.lastName}`,
                  email: data?.user?.email,
                  publicationName: data.publication.name,
                  companyName: data.publication.company.businessName,
                  message: data?.paymentHistory[0]?.message,
                  subType: data.subType,
                  amount: data?.paymentHistory[0]?.amount?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: data?.paymentHistory[0]?.currency,
                    }
                  ),
                  status: data.status,
                  paymentDate: moment(data.paymentDate).format("YYYY-MM-DD"),
                  nextPaymentDate: moment(data.nextPaymentDate).format(
                    "YYYY-MM-DD"
                  ),
                  paymentStatus: data?.paymentHistory[0]?.paymentStatus,
                  action: (
                    <button
                      type="button"
                      class={
                        data?.paymentHistory[0]?.paymentStatus === "successful"
                          ? "bg-green"
                          : "btn btn-dark"
                      }
                      style={{ fontSize: "8px" }}
                      onClick={
                        data?.paymentHistory[0]?.paymentStatus === "successful"
                          ? () => {}
                          : () =>
                              props.cancelSubscription(data?.user?.id, data.id)
                      }
                    >
                      {data?.paymentHistory[0]?.paymentStatus === "successful"
                        ? "Successful"
                        : "Cancel Re-subcription"}
                    </button>
                  ),
                };
              })
        }
        title="Subcription"
      />
    </div>
  );
};
