import React, { useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import validator from 'validator';
import { httpPost } from '../../../action/http';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { NotificationManager } from 'react-notifications';
import logo from '../../../assets/image/logo1.png';

const ResetPassword = (props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (validator.isEmpty(password)) {
      swal('error', 'Password cannot be empty', 'Error');
      return;
    }
    if (password !== confirmPassword) {
      swal('error', 'Password and confirm password must be equal', 'Error');
      return;
    }
    const data = {
      password,
    };
    const res = await httpPost(`/auth/user/reset_password/${token}`, data);
    if (res.code === 200) {
      swal('Done', res.message, 'success');
      props.history.push('/');
    } else {
      swal('Error', res.message, 'error');
      console.log(res);
      setLoading(false);
    }
  };
  return (
    <div className="row h-100">
      <div className="col-md-6 mx-auto my-auto h-50 text-center p-0">
        <img src={logo} alt="logo" />
        <h4 className="mt-5">Reset Password</h4>
        <form autocomplete="off">
          <div className="p-xs-5">
            <Input
              type="password"
              placeholder="Password"
              className="form-control col-md-6 col-sm-6 mx-auto"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />

            <Input
              type="password"
              placeholder="Confirm Password"
              className="form-control col-md-6 col-sm-6 mx-auto"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Button
              className="form-control col-md-6 col-sm-6 mx-auto mt-4"
              type="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
