import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import Layout from "../../shared/Layout";
import { SubscriptionTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPatch, httpPost } from "../../../action/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Link } from "react-router-dom";

const Index = ({ history }) => {
  const [subscription, setSubscription] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getAllSubscription();
    if (getDeletDetails.reload_state === true) {
      getAllSubscription();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllSubscription = async () => {
    try {
      const res = await httpGet("/admin/pn_subscriptions");
      if (res.code === 200) {
        setSubscription(res.data.pnSubscriptions);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const approveSubscription = async (status, id) => {
    const willDisable = await swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${
        status === "active"
          ? "approve"
          : status === "inactive"
          ? "Deactivate"
          : "reject"
      }?`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      showLoader();
      const data = {
        status,
      };
      const res = await httpPatch(
        `/admin/public_notice?id=${id}&status=${status}`,
        data
      );
      if (res.code === 200) {
        hideLoader();
        swal(
          "Done",
          `Successfully ${status === "active" ? "Approved" : "Rejected"}`,
          "success"
        );
        getAllSubscription();
      }
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={history}
        page="public_notice"
        activepage="keepOpenGroup"
      >
        <div
          className="text-right table-wrap"
          style={{
            padding: "0",
            background: "none",
          }}
        >
          <span
            style={{
              textDecoration: "none !important",
            }}
            onClick={() => {
              history.push("/public_notice/applications");
            }}
            className=" btn btn-primary text-white "
          >
            View Applications
          </span>
        </div>
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <div className="table-wrap">
            {/* <GroupsTable getGroup={getGroup}/> */}
            <SubscriptionTable
              getSubscriptions={subscription || []}
              approveSubscription={approveSubscription}
              getAllSubscription={getAllSubscription}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
    </div>
  );
};

export default Index;
