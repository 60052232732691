import React, { useState } from "react";
import { Modal } from "reactstrap";
import swal from "sweetalert";
import { hideLoader, showLoader } from "../../../utils/loader";
import "./style.css";

export default function Index(props) {
  const {
    showModal,
    hideModal,
    banks,
    getBankAccountDetails,
    data,
    setData,
    handleEditAccount,
    bankDetails,
  } = props;
  console.log("data>>>", data);

  const handleGetBank = (number) => {
    getBankAccountDetails(number, data?.bankCode || bankDetails?.bankCode);
  };

  return (
    <Modal
      centered
      toggle={() => {
        hideModal(false);
      }}
      isOpen={showModal}
    >
      <div className="account-modal">
        <div className="account-modal-head">
          <div>Update Account Details</div>
          <div className="head-x" onClick={() => hideModal(false)}>
            x
          </div>
        </div>
        <div className="account-input-flex">
          <div
            className="account-input"
            style={{
              width: "48%",
            }}
          >
            <div className="acc-in-title">Select Bank</div>
            <select
              name=""
              id=""
              className="account-input"
              onChange={(e) => {
                const [bankName, bankCode] = e.target.value.split(",");
                setData({ ...data, bankName, bankCode });
              }}
            >
              <option>{bankDetails?.bankName}</option>
              {banks?.length &&
                banks.map((bank) => (
                  <option
                    key={bank?.name}
                    value={`${bank?.name},${bank?.code}`}
                  >
                    {bank?.name}
                  </option>
                ))}
            </select>
          </div>
          <div
            className="account-input"
            style={{
              width: "48%",
            }}
          >
            <div className="acc-in-title">Account Number</div>
            <input
              maxLength={10}
              value={data?.accountNumber}
              type="text"
              placeholder={bankDetails?.accountNumber}
              onChange={(e) => {
                setData({
                  ...data,
                  accountNumber: e.target.value,
                  bankName: data?.bankName || bankDetails?.bankName,
                });
                if (e.target.value.length > 9) {
                  handleGetBank(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="account-input">
          <div className="acc-in-title">Account Name</div>
          <input
            type="text"
            placeholder={bankDetails?.accountName}
            value={data?.accountName}
            disabled
          />
        </div>

        <div className="account-modal-butt" onClick={handleEditAccount}>
          Update Account
        </div>
      </div>
    </Modal>
  );
}
