import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import DataTable from "../../shared/DataTable";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import FormatDate from "../../../helpers/formatDate";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";
import swal from "sweetalert";
import { hideLoader, showLoader } from "../../../utils/loader";
import "./style.css";

const Applications = (props) => {
  const [applications, setApplications] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const [loading, setLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    pulblication: "",
    reason: "",
    status: "",
  });
  const getAllApplicants = async () => {
    try {
      const res = await httpGet("/admin/con_applications");
      if (res.code === 200) {
        setApplications(res.data.conApplications);
        console.log("DATAS", res.data.conApplications);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };
  const approveSubscription = async (status, id) => {
    const willDisable = await swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${
        status === "active" ? "approve" : "reject"
      }?`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      showLoader();
      const data = {
        status,
      };
      const res = await httpPost(
        `/admin/con_approve?id=${id}&status=${status}`,
        data
      );
      if (res.code === 200) {
        hideLoader();
        getAllApplicants();
      }
    }
  };
  const data = applications
    .filter(
      (data) =>
        data?.publication?.name.includes(filterOptions.pulblication) &&
        data?.reason
          .toLowerCase()
          .includes(filterOptions.reason.toLowerCase()) &&
        data?.status.toLowerCase().includes(filterOptions.status.toLowerCase())
    )
    .map((data, index) => ({
      name: `${data?.user?.firstName} ${data?.user?.lastName} `,
      newName: `${data?.newFirstname} ${data?.newLastname} ${data?.newMiddlename}`,
      publishName: data?.publication?.name,
      publishedDate:
        data?.publishedDate === null ? "Not Published" : data?.publishedDate,
      reason: data?.reason,
      status:
        data?.status === null
          ? "Undefined"
          : CapitalizeFirstLetter(data?.status),
      createdAt: FormatDate(data.createdAt),
    }));

  useEffect(() => {
    getAllApplicants();
    if (getDeletDetails.reload_state === true) {
      getAllApplicants();
    }
  }, [getDeletDetails.reload_state === true]);

  return (
    <Layout
      RouteUserLayout={props.history}
      page="applications"
      activepage="keepOpenGroup"
    >
      <div className="filter-header">
        <p className="title">Filter</p>

        <div>
          <p className="section-title">Reason</p>
          <select
            value={filterOptions.reason}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                reason: e.target.value,
              })
            }
          >
            <option value="">Reason</option>

            <option value="Marriage">Marriage</option>
            <option value="Faith">Faith</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div>
          <p className="section-title">Status</p>

          <select
            value={filterOptions.status}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                status: e.target.value,
              })
            }
            style={{
              marginBottom: "15px",
            }}
          >
            <option value="">Status</option>

            <option value="Paid">Paid</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div>
          <p className="section-title">Pulblication Name</p>
          <select
            value={filterOptions.pulblication}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                pulblication: e.target.value,
              })
            }
            style={{
              marginBottom: "15px",
            }}
          >
            <option value="">Pulblication Name</option>
            {applications.map((data) => (
              <option key={data?.id} value={data?.publication?.name}>
                {data?.publication?.name}
              </option>
            ))}
          </select>
        </div>
        {filterOptions.pulblication ||
        filterOptions.reason ||
        filterOptions.status ? (
          <div>
            <button
              onClick={() =>
                setFilterOptions({ pulblication: "", reason: "", status: "" })
              }
              className="filter-clear"
            >
              Clear
            </button>
          </div>
        ) : null}
      </div>
      <div className="table-responsive">
        <DataTable
          title="Applications"
          columns={[
            { title: "Name", field: "name" },
            { title: "New Name", field: "newName" },
            { title: "Publication Name", field: "publishName" },
            { title: "Published Date", field: "publishedDate" },
            { title: "Reason", field: "reason" },
            { title: "Status", field: "status" },
            { title: "Applied On", field: "createdAt" },
          ]}
          data={data}
        />
      </div>
    </Layout>
  );
};

export default Applications;
