import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import successIcon from "../../../assets/image/check-circle-fill.svg";

function CreateCouponModal({ open, onClose, createCoupon }) {
  const [formData, setFormData] = useState({ amount: "" });
  const [isProcessing, setIsProcessing] = useState(false);
  const [formState, setFormState] = useState("form");
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async () => {
    setIsProcessing(true);
    try {
      const res = await createCoupon(formData);
      if (res.success) {
        setIsProcessing(false);
        setFormData({ amount: "" });
        setFormState("message");
      }
    } catch (error) {
      setIsProcessing(false);
    }
  };

  const handleDone = () => {
    onClose();
    setFormState("form");
  };

  return (
    <Modal
      open={open}
      onClose={handleDone}
      styles={{
        modal: {
          width: "300px",
          height: "300px",
          borderRadius: "8px",
        },
      }}
      center
    >
      {formState === "form" ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <label>
              Amount <span className="impt-i">*</span>
            </label>
            <input
              onChange={onChange}
              name="amount"
              type="tel"
              className="form-control"
              value={formData.amount}
              placeholder="Amount"
              required={true}
            />
          </div>

          <button
            disabled={isProcessing}
            style={{
              width: "266px",
              height: "45px",
              background: "#FFA03F",
              borderRadius: "7px",
              color: "#000",
            }}
            onClick={onSubmit}
            // className="editProfilebtn"
          >
            {isProcessing ? "Loading..." : "Submit"}
          </button>
        </div>
      ) : (
        <div
          style={{
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              textAlign: "center",
              color: "#425466",
              fontWeight: 700,
            }}
          >
            Successfully
          </p>
          <img src={successIcon} alt="icon" />
          <button
            onClick={handleDone}
            style={{
              width: "266px",
              height: "45px",
              background: "#FFA03F",
              borderRadius: "7px",
              color: "#000",
            }}
          >
            Done
          </button>
        </div>
      )}
    </Modal>
  );
}

export default CreateCouponModal;
