import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import CapitalizeFirstLetter from "./../../../helpers/capitalLizeFirstLetter";
export const CategoryTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getCategory.map((data, index) => ({
      name: CapitalizeFirstLetter(data.name),
      // description: data.description,
      action: (
        <div>
          <button
            className="btn button-primary text-white mr-3"
            onClick={() =>
              props.props.history.push(`/edit_category/${data.id}`)
            }
          >
            Edit
          </button>
          {/* <button
            className="btn btn-danger"
            onClick={() =>
              props.updateStatus(
                data.status === 'active' ? 'inactive' : 'active',
                data.id
              )
            }
          >
            {data.status === 'active' ? 'Disable' : 'Enable'}
          </button> */}
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Name (filterable)",
        prop: "name",
        sortable: true,
        filterable: true,
      },

      // { title: 'Description', prop: 'description' },

      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
