import React, { useState } from "react";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../../helpers/formatDate";
import Switch from "react-switch";
const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

export default function UserTransationTable({
  Vendorpublications,
  switchPublicationTabFun,
}) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          pageSizeOptions: [10, 20, 50, 100, 200],
          pageSize: 10,
        }}
        columns={[
          { title: "Publication Name", field: "publicationName" },
          { title: "Transactions", field: "Transactions" },
          // { title: "Action", field: "action" },
          //   { title: "Status ", field: "Status" },
        ]}
        data={Vendorpublications?.map((data) => {
          return {
            Transactions: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  switchPublicationTabFun(
                    data.id,
                    "publicationTrans",
                    data?.name
                  )
                }
              >
                View Transactions
              </span>
            ),
            publicationName: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  switchPublicationTabFun(
                    data.id,
                    "publicationEditions",
                    data?.name
                  )
                }
              >
                {data?.name}
              </span>
            ),
            // action: (
            //   <Switch
            //     checked={data.action}
            //     onColor="#14274E"
            //     uncheckedIcon={data.action}
            //     checkedIcon={data.action}
            //     height={20}
            //     width={40}
            //     handleDiameter={17}
            //     onChange={handleCategoryChange}
            //   />
            // ),
            // Status: data?.status,
          };
        })}
        title="Vendor Publications"
      />
    </div>
  );
}
