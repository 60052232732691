import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { ProductTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPatch } from "../../../action/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import swal from "sweetalert";
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [products, setProducts] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  useEffect(() => {
    getAllProducts();
    if (getDeletDetails.reload_state === true) {
      getAllProducts();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllProducts = async () => {
    try {
      const res = await httpGet(`/publication_type`);
      if (res.code === 200) {
        setProducts(res.data.publicationTypes);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const handleUpdateStatus = async (status, id) => {
    const willDisable = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to deactivate this product?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      try {
        showLoader();
        const res = await httpPatch(
          `/admin_product_status?status=${status}&id=${id}`
        );
        if (res.code === 200) {
          swal("Done", res.message, "success").then(() => getAllProducts());
          hideLoader();
        }
      } catch (error) {
        hideLoader();
        return;
      }
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="products"
        activepage="keepOpenGroup"
      >
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <div className="table-wrap">
            {/* <div>
              <button
                className="create-ntn654ty"
                onClick={() => props.history.push("/create_publication")}
              >
                Create new publication
              </button>
            </div> */}

            {/* <GroupsTable getGroup={getGroup}/> */}
            <ProductTable
              getProducts={products || []}
              updateStatus={handleUpdateStatus}
              props={props}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllProducts={getAllProducts}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
