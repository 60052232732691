import React from "react";
import Input from "../../shared/Input";
import Button from "../../shared/Button";

const Create = (props) => {
  return (
    <div className="col col-sm-6 category-form">
      <div className="form-group">
        <label>Recipent Type</label>
        <div className="form-check form-check-inline ml-4">
          <input
            type="radio"
            className="mr-3"
            id="type"
            name="type"
            checked={props.data.type === "user" && true}
            onClick={(e) =>
              e.target.checked && props.setData({ ...props.data, type: "user" })
            }
          />
          <label className="form-check-label" for="type">
            User
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input mr-3 ml-4"
            type="radio"
            checked={props.data.type === "vendor" && true}
            id="type"
            name="type"
            onClick={(e) =>
              e.target.checked &&
              props.setData({ ...props.data, type: "vendor" })
            }
          />
          <label className="form-check-label" for="type1">
            Vendor
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input mr-3 ml-4"
            type="radio"
            checked={props.data.type === "both" && true}
            id="type"
            name="type"
            onClick={(e) =>
              e.target.checked && props.setData({ ...props.data, type: "both" })
            }
          />
          <label className="form-check-label" for="type1">
            Both (User/Vendor)
          </label>
        </div>
      </div>

      {/* <div className="form-group">
        <label>Message Title</label>
        <Input
          className="form-control"
          type="text"
          placeholder="Default to 1 if no number is inserted"
          value={props.data.title}
          onClick={(e) =>
            props.setData({ ...props.data, title: e.target.value })
          }
        />
      </div> */}

      <div className="form-group">
        <label>
          Description <span className="impt-i">*</span>
        </label>
        <textarea
          value={props.data.description}
          onChange={(e) =>
            props.setData({ ...props.data, description: e.target.value })
          }
          name="description"
          className="form-control"
          id="Presenter"
          placeholder="About Category"
          required
        />
      </div>

      <div className="w-100 mt-3 mb-5">
        <Button
          type="submit"
          onClick={props.handleSubmit}
          className="button-primary"
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default Create;
