import React, { useState } from 'react';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { Modal, ModalBody } from 'reactstrap';

const ServiveCharge = ({ showModal, hideModal, handleSubmit, subscriptions, setSubscriptions, loading }) => {
  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => {
          hideModal(false);
        }}
        centered
      >
        <ModalBody className="p-4 ">
          <div className="form ">
            <p className=" form-control  border-0 text-center mb-3 font-weight-bold">Input New Service Charge</p>
            <Input
              type="text"
              placeholder=""
              className="form-control"
              value={subscriptions.serviceCharge}
              onChange={(e) => {
                setSubscriptions({
                  ...subscriptions,
                  serviceCharge: e.target.value,
                });
              }}
            />
            <div className="d-flex my-3" style={{ justifyContent: 'center' }}>
              <Button className="p-2 form-control btn-outline-dark text-dark" type="" loading={loading} onClick={() => hideModal(false)}>
                Cancel
              </Button>
              <Button className="btn-success p-2 form-control ml-3 " type="submit" onClick={handleSubmit} loading={loading}>
                Save
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ServiveCharge;
