import React from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import icons from "./icons";
export default function DataTable({ columns, data, title, onRowClick }) {
  return (
    <div
      classaction="table-wrap border styleTable"
      style={{
        maxWidth: "100%",
        borderRadius: "4px",
        fontWeight: "normal",
      }}
    >
      <MaterialTable
        components={{
          Container: (props) => (
            <Paper
              {...props}
              style={{
                padding: "10px",
              }}
            />
          ),
        }}
        onRowClick={onRowClick}
        icons={icons}
        options={{
          selection: false,
          exportButton: true,
          exportAllData: true,
          pageSize: 20,
        }}
        columns={columns}
        data={data || []}
        title={title}
      />
    </div>
  );
}
