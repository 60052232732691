import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Loader from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpGet, httpPost, httpPatch, httpPut } from "../../../action/http";
import MaleAvatar from "./male.png";
import { ValidateInput, ValidateEmail } from "../../../helpers/validateInput";
// import { UserDataContext } from "../../../context/userContext";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Avatar from "react-avatar-edit";
import swal from "sweetalert";
import UploadImage from "../../../helpers/uploadImage";
import Layout from "../../shared/Layout";
import { hideLoader, showLoader } from "../../../utils/loader";
import HeaderSection from "./profileHeader";
import ProfileInputesSection from "./mainProfile";
import VendorPublications from "./vendorPublications";
import UserCategories from "./categories";
import AgentDrivers from "./agentDrives";
import VendorAccountDetails from "./vendorAccountDetails";
import EditionsTable from "./editionsTable";
import TransactionTable from "./transationsTable";
import ShareRatio from "./shareRatio";
export default function Home(props) {
  const [EditProfilePicModalOpen, setEditProfilePicModalOpen] = useState(false);
  const OpenEditProfilePicModalModal = () =>
    setEditProfilePicModalOpen(!EditProfilePicModalOpen);
  const setOpenEditProfilePicModalModal = () =>
    setEditProfilePicModalOpen(false);

  const [ChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [vendorPublication, setvendorPublication] = useState([]);
  const OpenChangePasswordModalModal = () => setChangePasswordModalOpen(true);
  const setOpenChangePasswordModalModal = () =>
    setChangePasswordModalOpen(false);

  const [userAccount, setUserAccount] = useState({
    email: "",
    companyName: "",
    rcNumber: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    avatar: "",
    repeatCurrentPassword: "",
    currentPassword: "",
    oldPassword: "",
  });
  const [activeTabProfile, setactiveTabProfile] = useState("profile");
  const getUserInfo = () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    //setuserDataFromBackend(parseUserData);
    console.log("parseUserData>>", parseUserData);
  };

  useEffect(() => {
    getVendorInfo();

    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, []);

  const onClose = () => {
    setPreview(preview);
  };
  const [imgSrc, setimgSrc] = useState(null);

  const [preview, setPreview] = useState(
    // userDataFromBackend.avatar === null
    //   ? MaleAvatar
    //   : userDataFromBackend.avatar
    MaleAvatar
  );
  const onCrop = (preview) => {
    console.log(imgSrc);
    setPreview(preview);
    dataURLtoFile(preview, `userAvatar`);
    setUserAccount({ ...userAccount, avatar: preview });
  };
  const [imageFile, setImageFile] = useState("");
  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const f = new File([u8arr], filename, { type: mime });
    setImageFile(f);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 921680) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    rcNumber: "",
    companyName: "",
    email: "",
    password: "",
    mobile: "",
    repeatCurrentPassword: "",
    currentPassword: "",
    oldPassword: "",
    username: "",
    firstName: "",
    lastName: "",
    tagLine: "",
    phoneNumber: "",
    address: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "rcNumber") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, rcNumber: testValid });
    }

    if (e.target.name === "companyName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, companyName: testValid });
    }

    if (e.target.name === "repeatCurrentPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, repeatCurrentPassword: testValid });
    }

    if (e.target.name === "currentPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, currentPassword: testValid });
    }

    if (e.target.name === "oldPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, oldPassword: testValid });
    }

    if (e.target.name === "username") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, username: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }

    if (e.target.name === "firstName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
    }

    if (e.target.name === "lastName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, lastName: testValid });
    }

    if (e.target.name === "address") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, address: testValid });
    }

    if (e.target.name === "phoneNumber") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, phoneNumber: testValid });
    }

    if (e.target.name === "tagLine") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, tagLine: testValid });
    } else {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
    }
    console.log(userAccount);
  };

  const UpdateUserAvatar = async () => {
    if (imageFile === "") {
      NotificationManager.error("Please Upload An Image");
      return;
    }
    showLoader();
    const im = await UploadImage(imageFile);
    const data = { avatar: im.imagePath };
    let res = await httpPut(`/auth/edit_profile`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }

      hideLoader();
      NotificationManager.success("Profile Image Updated Successfully.");
      setOpenEditProfilePicModalModal();
    }
  };

  const updateUserProfile = async () => {
    // if (errorInput.com) {

    // }
    let data = {
      email: userAccount.email,
      companyName: userAccount.companyName,
      rcNumber: userAccount.rcNumber,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      phoneNumber: userAccount.phoneNumber,
      address: userAccount.address,
    };
    showLoader();
    let res = await httpPut(`/auth/edit_profile`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }

      hideLoader();
      NotificationManager.success("Profile details updated successfully");
      console.log(">>>user", res);
    }
  };
  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
    oldPassword: "",
  });
  const handleUserPasswordInputChange = async (e) => {
    setUserPassword({
      ...userPassword,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassword = async () => {
    if (userPassword.confirmNewPassword !== userPassword.newPassword) {
      return swal({
        title: "Opps!",
        text: "Passwords do not match!",
        icon: "warning",
        dangerMode: true,
      });
    }
    const data = {
      newPassword: userPassword.newPassword,
      currentPassword: userPassword.oldPassword,
    };
    showLoader();
    let res = await httpPatch(`/auth/change_password`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        return NotificationManager.error(res.er.message);
      }
      setUserPassword({
        newPassword: "",
        confirmNewPassword: "",
        oldPassword: "",
      });
      hideLoader();
      setOpenChangePasswordModalModal();
      swal("Yepp", "Password updated  successfully.");
      console.log("user>>>>", res);
    }
  };

  const handleTabSection = (page) => {
    setactiveTabProfile(page);
  };
  const [vendorcategories, setvendorcategories] = useState([]);
  const [publicationTransactions, setpublicationTransactions] = useState([]);
  const [userAccountDet, setuserAccountDet] = useState([]);
  const [vendorReport, setVendorReport] = useState(null);
  const getVendorInfo = async () => {
    showLoader();
    const vendorDetails = await httpGet(
      `/admin/vendor_details/${props.match.params.id}`
    );
    const vendorPublications = await httpGet(
      `/admin/vendor_publications/${props.match.params.id}`
    );

    const VendorCategory = await httpGet(
      `/admin/vendor_category/${props.match.params.id}`
    );

    const publicationTransactions = await httpGet(
      `/admin/publication_transactions/${props.match.params.id}`
    );

    if (
      vendorDetails.code === 200 ||
      vendorPublications.code === 200 ||
      VendorCategory.code === 200
    ) {
      hideLoader();
      setvendorPublication(vendorPublications?.data?.publications);
      setvendorcategories(VendorCategory?.data?.categories);
      setpublicationTransactions(publicationTransactions?.data);
      setuserAccountDet(vendorDetails?.data);
      setUserAccount({
        email: vendorDetails?.data?.company.email,
        companyName: vendorDetails?.data?.company.businessName,
        rcNumber: vendorDetails?.data?.company.rcNumber,
        firstName: vendorDetails?.data?.company.firstName,
        lastName: vendorDetails?.data?.company.lastName,
        phoneNumber: vendorDetails?.data?.company.phone,
        address: vendorDetails?.data?.company.address,
        avatar: vendorDetails?.data?.company.avatar,
        password: "",
        repeatCurrentPassword: "",
        currentPassword: "",
        oldPassword: "",
      });
    }
  };

  const [Showloading, setLoading] = useState(false);
  const [editions, setEditions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [PublicationName, setPublicationName] = useState("");
  const getPublicationEdition = async (id) => {
    showLoader();
    const getEdition = await httpGet(`/admin/vendor_editions/${id}`);
    if (getEdition.code === 200) {
      setEditions(getEdition.data.editions);

      hideLoader();
    }
  };

  const getPublicationTrans = async (id) => {
    showLoader();
    const getEdition = await httpGet(`/admin/publication_transactions/${id}`);
    if (getEdition.code === 200) {
      setTransactions(getEdition.data.transactions);

      hideLoader();
    }
  };

  const switchPublicationTabFun = (id, type, publicationName) => {
    if (type === "publicationTrans") {
      setactiveTabProfile(type);
      getPublicationTrans(id);
      setPublicationName(publicationName);
    }

    if (type === "publicationEditions") {
      setactiveTabProfile(type);
      getPublicationEdition(id);
      setPublicationName(publicationName);
    }
  };

  const [getRatioSubmit, setGetRatioSubmit] = useState("");

  const submitPercentTageRatio = async () => {
    let data = {
      percentageRatio: getRatioSubmit,
      id: userAccountDet.company.id,
    };
    const res = await httpPost("/admin/company_ratio", data);
    if (res.code === 200) {
      swal("Yepp", "Vendor ratio updated successfully");
      getVendorInfo();
    }
  };

  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <Layout
        RouteUserLayout={props.history}
        page="settings"
        activepage="keepOpenGroup"
      >
        <React.Fragment>
          <div className="section-wrap pPageSec">
            <HeaderSection
              userAccount={userAccountDet}
              OpenEditProfilePicModalModal={OpenEditProfilePicModalModal}
              vendorReport={vendorReport}
            />
            <div className="tabSwitcher">
              <span
                className={`${
                  activeTabProfile == "profile" ? "activepSepPage" : ""
                } `}
                onClick={() => handleTabSection("profile")}
              >
                Profile
              </span>
              <span
                className={`${
                  activeTabProfile === "publications" ? "activepSepPage" : ""
                } `}
                onClick={() => handleTabSection("publications")}
              >
                Publications
              </span>

              <span
                className={`${
                  activeTabProfile === "accountDetails" ? "activepSepPage" : ""
                } `}
                onClick={() => handleTabSection("accountDetails")}
              >
                Account Details
              </span>
              <span
                className={`${
                  activeTabProfile === "agent" ? "activepSepPage" : ""
                } `}
                onClick={() => handleTabSection("agent")}
              >
                Role
              </span>

              <span
                className={`${
                  activeTabProfile === "ShareRatio" ? "activepSepPage" : ""
                } `}
                onClick={() => handleTabSection("ShareRatio")}
              >
                Share Ratio
              </span>
            </div>
            <div className="hrsolidpi"></div>
            {activeTabProfile == "publicationEditions" ? (
              <div className="editionsTable">
                <EditionsTable
                  editions={editions}
                  PublicationName={PublicationName}
                />
              </div>
            ) : (
              ""
            )}

            {activeTabProfile == "publicationTrans" ? (
              <div className="editionsTable">
                <TransactionTable
                  transactions={transactions}
                  PublicationName={PublicationName}
                />
              </div>
            ) : (
              ""
            )}
            {activeTabProfile == "profile" ? (
              <ProfileInputesSection
                handleChange={handleChange}
                userAccount={userAccount}
                updateUserProfile={updateUserProfile}
              />
            ) : (
              ""
            )}

            {activeTabProfile == "publications" ? (
              <VendorPublications
                Vendorpublications={vendorPublication}
                switchPublicationTabFun={switchPublicationTabFun}
                userAccount={userAccount}
              />
            ) : (
              ""
            )}

            {activeTabProfile == "accountDetails" ? (
              <VendorAccountDetails userAccountDet={userAccountDet.company} />
            ) : (
              ""
            )}
            {activeTabProfile == "category" ? (
              <UserCategories vendorcategories={vendorcategories} />
            ) : (
              ""
            )}
            {activeTabProfile == "agent" ? (
              <AgentDrivers vendorTeam={userAccountDet} />
            ) : (
              ""
            )}

            {activeTabProfile == "ShareRatio" ? (
              <ShareRatio
                userAccountDet={userAccountDet}
                setGetRatioSubmit={setGetRatioSubmit}
                submitPercentTageRatio={submitPercentTageRatio}
              />
            ) : (
              ""
            )}
            <br />
          </div>
          <Modal
            open={EditProfilePicModalOpen}
            onClose={OpenEditProfilePicModalModal}
          >
            <div className="inner-modal-wrap">
              <div className="avatarProfileImageCrop">
                {" "}
                <Avatar
                  width={400}
                  height={295}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={imgSrc}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={`${
                    userAccount.avatar === null
                      ? MaleAvatar
                      : `${userAccount.avatar}`
                  }`}
                  alt="Preview"
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <button
                  style={{ marginRight: "25px" }}
                  onClick={UpdateUserAvatar}
                  className="editProfilebtn"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      </Layout>
    );
  }
}
