import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout";
import DataTable from "../../shared/DataTable";
import { useRecoilState } from "recoil";
import { httpGet } from "../../../action/http";
import FormatDate from "../../../helpers/formatDate";
import Button from "../../shared/Button";
import Details from "./Details";

const Applications = (props) => {
  const [applications, setApplications] = useState([]);
  const [pnStatus, setPnStatus] = useState("table");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getAllApplicants = async () => {
    try {
      const res = await httpGet("/admin/pn_applications");
      if (res.code === 200) {
        setApplications(res?.data?.pnApplications);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setPnStatus("table");
    getAllApplicants();
  }, []);

  console.log("appli>>", applications);

  return (
    <Layout
      RouteUserLayout={props.history}
      page="pnApplications"
      activepage="keepOpenGroup"
    >
      {pnStatus === "table" && (
        <div className="table-responsive">
          <DataTable
            title="Applications"
            columns={[
              { title: "Name", field: "name" },
              { title: "Publication Name", field: "publishName" },
              { title: "Notice Type", field: "type" },
              { title: "Status", field: "status" },
              { title: "Date Applied", field: "createdAt" },
              { title: "More", field: "more" },
            ]}
            data={applications.map((item) => ({
              name: `${item?.user?.firstName} ${item?.user?.lastName}`,
              status: item?.status,
              publishName: item?.publication?.name,
              type: item?.category,
              createdAt: FormatDate(item?.createdAt),
              more: (
                <Button
                  type="button"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  content="view more"
                  onClick={() => {
                    setData(item);
                    setPnStatus("det");
                  }}
                />
              ),
            }))}
          />
        </div>
      )}

      {pnStatus === "det" && (
        <Details setData={setData} setPnStatus={setPnStatus} data={data} />
      )}
    </Layout>
  );
};

export default Applications;
