import React, { ref, useRef } from "react";
import MaleAvatar from "./male.png";
export default function ProfileHeader({ userAccount }) {
  const {
    totalEarnings,
    totalEditions,
    totalPublications,
    totalTransactions,
  } = userAccount;
  // const changeProfileImage = useRef("overlayBoxEditImagewrapHide");
  const showEditImageHandler = (inn) => {
    // if (inn === "in") {
    //   changeProfileImage.current.className = "overlayBoxEditImagewrap";
    // }
    // if (inn === "out") {
    //   changeProfileImage.current.className = "overlayBoxEditImagewrapHide";
    // }
  };
  return (
    <div className="headerWrapdfghkjht">
      <div className="profilePageProfileTitle">
        <span>Vendor Profile</span>
      </div>

      <div className="profileSummarySection">
        <div className="productCoutCardsPOp">
          <div className="Pcard1">
            <span>TOTAL PUBLICATIONS</span>
            <span>{totalPublications}</span>
          </div>
          <div className="Pcard2">
            <span>TOTAL REVENUE</span>
            <span>N{totalEarnings === 0 ? "0" : totalEarnings}</span>
          </div>
        </div>
        <div className="addMordderToProfileSecBoxs"></div>
        <div className="productCoutCardsPOp">
          <div style={{ backgroundColor: "#000000" }} className="Pcard1">
            <span style={{ color: "#FFFFFF" }}>TOTAL EDITIONS</span>
            <span style={{ color: "white" }}>{totalEditions}</span>
          </div>
          <div style={{ opacity: 0 }} className="Pcard2">
            <span>PENDING ORDERS</span>
            {/* <span>{vendorReport?.pendingOrders}</span> */}
          </div>
        </div>
      </div>

      <div className="hrProfilePage"></div>
    </div>
  );
}
