import React, { Component } from 'react';
const OverviewStat = (props) => {
  const {
    successfulTransaction,
    userCount,
    vendorCount,
    productCount,
  } = props.stats;
    return (
      <React.Fragment>
        <div className="staticticsOverView">
          <div>
            <h1>  {userCount}</h1>
            <h2>Users </h2>
          </div>

          <div>
            <h1>{vendorCount}</h1>
            <h2>Vendor</h2>
          </div>

          <div>
            <h1>{successfulTransaction}</h1>
            <h2>Successful Transaction </h2>
          </div>
        </div>
      </React.Fragment>
    );
  }

  export default OverviewStat