import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import { Percentage } from "./../../../helpers/ratio";
export default function ShareRatio({
  setGetRatioSubmit,
  submitPercentTageRatio,
  userAccountDet,
}) {
  const useStyles = makeStyles({
    root: {
      width: 300,
    },
  });
  const [getTnsRatio, setTnsRatio] = useState(0);
  const [getVendorRatio, setVendorRatio] = useState(
    userAccountDet?.company?.percentageRatio
  );
  function valuetext(value) {
    setTnsRatio(100 - Number(value));
    setVendorRatio(Number(value));
    setGetRatioSubmit(Number(value));
    return `${value}°C`;
  }
  const classes = useStyles();

  useEffect(() => {
    setVendorRatio(Number(userAccountDet?.company?.percentageRatio));
    setTnsRatio(100 - Number(userAccountDet?.company?.percentageRatio));
    // setVendorRatio(Number(userAccountDet?.company?.percentageRatio));
    console.log("comoan>>>>>>>>>>>", userAccountDet);
  }, [userAccountDet, getTnsRatio, getVendorRatio]);
  return (
    <div>
      <div className="shareRatioHeader">Share Ratio</div>
      <div className="hrProfilePage"></div>
      <div className="shareRatioWrap">
        <span className="shareRatioWrapSpan">Vendor : {getVendorRatio}%</span>{" "}
        <span className="shareRatioWrapSpan">
          <div className={classes.root}>
            <Slider
              defaultValue={getVendorRatio}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-always"
              valueLabelDisplay="auto"
              valueLabelDisplay="on"
              step={5}
              marks
              min={5}
              max={100}
            />
          </div>
        </span>
        <span className="shareRatioWrapSpan">TNS : {getTnsRatio}%</span>{" "}
      </div>

      <div className="saveRatioChanges">
        <button onClick={submitPercentTageRatio}>Save Changes</button>
      </div>
    </div>
  );
}
