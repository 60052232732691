import React, { useState } from "react";
import Layout from "../../shared/Layout";
import CouponTable from "./CouponTable";
import "./coupon.css";
import useCoupon from "./useCoupon";
import CreateCouponModal from "./CreateCoupon";

function CouponMgt({ history }) {
  const [open, setOpen] = useState(false);
  const { coupons, createCoupon, expireCoupon } = useCoupon();
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <Layout
      RouteUserLayout={history}
      page="coupon_mgt"
      activepage="keepOpenGroup"
    >
      <div className="table-wrap">
        <div>
          <button className="add-coupon-button" onClick={onOpenModal}>
            Add New Coupon
          </button>
        </div>
        <CouponTable coupons={coupons} expireCoupon={expireCoupon} />
      </div>
      <CreateCouponModal
        open={open}
        onClose={onCloseModal}
        createCoupon={createCoupon}
      />
    </Layout>
  );
}

export default CouponMgt;
