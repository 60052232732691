import React from "react";
import Icon from "./location.png";
import Publications from "./publications";

export default function CoverageArea({
  Vendorpublications,
  switchPublicationTabFun,
}) {
  return (
    <div style={{ marginTop: "25px" }}>
      <Publications
        Vendorpublications={Vendorpublications}
        switchPublicationTabFun={switchPublicationTabFun}
      />
    </div>
  );
}
