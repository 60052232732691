import React from "react";

export default function MainProfile({
  handleChange,
  userAccount,
  updateUserProfile,
}) {
  return (
    <div className="mainUserInfoBox">
      <div className="inputWrapPPage">
        <div className="inputLabelBox">
          <label htmlFor="">Company Name</label>
        </div>
        <div className="inputBox">
          <input
            type="text"
            name="companyName"
            onChange={handleChange}
            value={userAccount?.companyName}
            type="text"
            placeholder="Company Name"
          />
        </div>
      </div>

      <div className="inputWrapPPage">
        <div className="inputLabelBox">
          <label htmlFor="">Full Name</label>
        </div>
        <div className="inputBox">
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            value={userAccount?.firstName}
            type="text"
            placeholder="First Name"
          />

          <input
            style={{ marginLeft: "15px" }}
            type="text"
            name="lastName"
            onChange={handleChange}
            value={userAccount?.lastName}
            type="text"
            placeholder="Last Name"
          />
        </div>
      </div>

      <div className="inputWrapPPage">
        <div className="inputLabelBox">
          <label htmlFor="">Mobile</label>
        </div>
        <div className="inputBox">
          <input
            type="text"
            name="phoneNumber"
            onChange={handleChange}
            value={userAccount?.phoneNumber}
            type="text"
            placeholder="Phone Number"
          />
        </div>
      </div>

      <div className="inputWrapPPage">
        <div className="inputLabelBox">
          <label htmlFor="">Email</label>
        </div>
        <div className="inputBox">
          <input
            type="text"
            disabled
            onChange={handleChange}
            value={userAccount?.email}
            type="text"
            placeholder="Email"
          />
        </div>
      </div>

      <div className="inputWrapPPage">
        <div className="inputLabelBox">
          <label htmlFor="">Status</label>
        </div>
        <div className="inputBox">
          <span>Active</span>
        </div>
      </div>

      <div className="saveProfileDetails">
        <button onClick={updateUserProfile}>Save Changes</button>
      </div>
    </div>
  );
}
