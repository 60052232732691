import React, { useState } from 'react';
import icons from '../table/icons';
import MaterialTable, { Column } from 'material-table';
import { Paper, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import dateFormater from './../../../helpers/formatDate';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import FilterIcon from '@material-ui/icons/FilterList';
import moment from 'moment';

export default function UserTransationTable({ transactions, PublicationName }) {
  function isDateInRange1(dateFrom, dateTo, dateToBeFiltered) {
    return dateFrom <= dateToBeFiltered && dateToBeFiltered <= dateTo;
  }

  const FilterNumericBetween = (props) => {
    const { columnDef, onFilterChanged } = props;

    return (
      <>
        <Accordion elevation={1} style={{ display: 'block' }}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <FilterIcon /> <Typography>Filter Date</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <TextField
              style={{ marginTop: '-20px', marginBottom: '20px' }}
              type="date"
              value={_.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || ''}
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateFrom = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
            <TextField
              type="date"
              value={_.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || ''}
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateTo = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          filtering: true,
          exportAllData: true,
          pageSizeOptions: [10, 20, 50, 100, 200],
          pageSize: 10,
        }}
        columns={[
          // {
          //   title: 'Transaction Id',
          //   field: 'reference',
          //   filtering: false,
          // },
          { title: 'Name', field: 'customerId' },

          { title: 'Transaction Type', field: 'product', filtering: false },

          { title: 'Amount', field: 'amount', filtering: false },

          {
            title: 'Customer Email',
            field: 'customer',
            filtering: false,
          },

          {
            title: 'Status',
            field: 'status',
            lookup: { active: 'active', expired: 'expired' },
          },
          {
            title: 'Subcription Type',
            field: 'subType',
            lookup: {
              monthly: 'monthly',
              weekly: 'weekly',
              one_off: 'one-off',
              quarterly: 'quarterly',
              annually: 'annually',
              biannually: 'biannually',
            },
          },
          {
            title: 'Date',
            field: 'date',
            filterComponent: FilterNumericBetween,
            customFilterAndSearch: (filter, rowData) => isDateInRange1(filter.dateFrom, filter.dateTo, rowData.date),
          },
        ]}
        data={transactions?.map((data) => {
          return {
            // reference: <Link to={`/view_transaction/${data?.paymentHistory[0].id}`}>{data?.paymentHistory[0].reference}</Link>,
            customerId: data.customerId,
            product: data?.paymentHistory[0].message,
            amount: `NGN${data?.amount}`,
            customer: data?.paymentHistory[0].customerEmail,
            status: data?.status,
            subType: data?.subType,
            date: moment(data?.createdAt).format('YYYY-MM-DD'),
          };
        })}
        title={`${PublicationName} Transactions`}
      />
    </div>
  );
}
