import React, { useState } from "react";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../../helpers/formatDate";
import Switch from "react-switch";
import CapitalizeFirstLetter from "./../../../helpers/capitalLizeFirstLetter";
const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

const handleCategoryChange = () => {
  alert("hello");
};

export default function UserTransationTable({ vendorcategories }) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Category", field: "Category" },
          // { title: "Action", field: "action" },
          { title: "Status ", field: "Status" },
        ]}
        data={vendorcategories?.map((data) => {
          return {
            Category: CapitalizeFirstLetter(data?.title),
            // action: (
            //   <Switch
            //     checked={data.action}
            //     onColor="#14274E"
            //     uncheckedIcon={data.action}
            //     checkedIcon={data.action}
            //     height={20}
            //     width={40}
            //     handleDiameter={17}
            //     onChange={handleCategoryChange}
            //   />
            // ),
            Status: data?.status,
          };
        })}
        title="Categories"
      />
    </div>
  );
}
