import React, { useContext } from "react";
import socketIO from "socket.io-client";
import { NotificationManager } from "react-notifications";
import { baseUrl } from "./http";
export const AppSocket = {};
export let notifications = [{}];

AppSocket.createConnection = async () => {
  if (AppSocket.io?.connected) return; // if there has been a connection before, return
  //open a connection
  var connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
  };

  AppSocket.io = socketIO(
    `${baseUrl}?token=${localStorage.token}`,
    connectionOptions
  );
  //listen for connection
  AppSocket.io.on("connect", () => {
    console.log("connected to server");
  });

  AppSocket.io.on("notification", (data) => {
    console.log("this is a notification", data);
  });
};

AppSocket.sendRequest = async (path, payload) => {
  if (AppSocket.io && AppSocket.io.connected === true) {
    AppSocket.io.emit(path, payload);
    NotificationManager.success(" Sent successfully.", "Success");
  } else {
    console.log("App disconnected from server");
  }
};
