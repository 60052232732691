import React from 'react';
import CurrencyInput from 'react-currency-input';

const MoneyInput = (props) => {
  const amount = '0.00';
  return (
    <CurrencyInput
      className={props.className}
      placeholder={props.placeholder}
      value={props.value || amount}
      onChangeEvent={props.onChange}
    />
  );
};
export default MoneyInput;
