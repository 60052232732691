import React from "react";
import Icon from "./location.png";
import CategoriesTable from "./categoryTable";

export default function CoverageArea({ vendorcategories }) {
  return (
    <div style={{ marginTop: "25px" }}>
      <CategoriesTable vendorcategories={vendorcategories} />
    </div>
  );
}
