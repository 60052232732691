export const SumUp = (items) => {
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

export function calculatePercentage(percent, amount) {
  console.log("==>", percent, Number(amount));
  if (Number(amount) === 0) {
    return 0.0;
  }
  return ((percent / 100) * Number(amount)).toFixed(2);
}
