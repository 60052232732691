import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { CategoryTable } from "./CategoryTable";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPatch } from "../../../action/http";
import { set } from "lodash";
import { hideLoader, showLoader } from "../../../utils/loader";
import swal from "sweetalert";
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [categories, setCategories] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllCategories();
  }, []);

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  // useEffect(() => {
  //   //getAllCategories();
  //   if (getDeletDetails.reload_state === true) {
  //     getAllCategories();
  //   }
  // }, [getDeletDetails.reload_state === true]);

  const getAllCategories = async () => {
    try {
      const res = await httpGet("/categories");
      if (res.code === 200) {
        //console.log(res.data.categories);
        setCategories(res.data.categories);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const handleUpdateStatus = async (status, id) => {
    const willDisable = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to disable this category?",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      try {
        showLoader();
        const res = await httpPatch(
          `/admin_category_status?status=${status}&id=${id}`
        );
        if (res.code === 200) {
          swal("Done", res.message, "success").then(() => getAllCategories());
          hideLoader();
        }
      } catch (error) {
        hideLoader();
        return;
      }
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="categories"
        activepage="keepOpenGroup"
      >
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <div className="table-wrap">
            <div>
              <button
                className="create-ntn654ty"
                onClick={() => props.history.push("/create_category")}
              >
                Create new category
              </button>
            </div>

            {/* <GroupsTable getGroup={getGroup}/> */}
            <CategoryTable
              getCategory={categories || []}
              updateStatus={handleUpdateStatus}
              props={props}
            />
          </div>
        )}
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllGroups={getAllGroups}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
