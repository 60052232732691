import React from 'react';
import { useRecoilState } from 'recoil';
import { NotificationManager } from 'react-notifications';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { showLoader, hideLoader } from '../../../utils/loader';
import { httpDelete } from '../../../action/http';

export const ConfirmModal = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const DeleteMaster = async () => {
    showLoader();

    try {
      const res = await httpDelete(
        `${getDeletDetails.delete_url}/${getDeletDetails.delete_id}/`
      );
      if (res.status === 204) {
        hideLoader();
        console.log(res);
        NotificationManager.success('Delected Successfully', 'Yepp!', 3000);
        setDeletDetails({ ...getDeletDetails, reload_state: true });
      }
    } catch (error) {}
  };

  return (
    <div>
      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ width: '375px' }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="confirmWrapper">
                <p>Are you sure you want to delete this?</p>
                <button
                  onClick={() => DeleteMaster()}
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
