import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import validator from "validator";
import ImageUploader from "react-images-upload";
import Layout from "../../shared/Layout";
import Input from "../../shared/Input";
import Button from "../../shared/Button";
import { httpGet, httpPost, httpPostData } from "../../../action/http";
import removeEmptyString from "../../../utils/removeEmptyString";
import MoneyInput from "../../shared/MoneyInput";
import { hideLoader, showLoader } from "../../../utils/loader";

const Create = (props) => {
  const [data, setData] = useState({});
  const [pictures, setPictures] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const res = await httpGet("/all_category");
      if (res.code === 200) {
        setCategories(res.data.categories);
      }
    } catch (error) {
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    if (validator.isEmpty(data.firstName)) {
      swal("error", "First name cannot be empty", "Error");
      return;
    }
    if (validator.isEmpty(data.lastName)) {
      swal("error", "Last name cannot be empty", "Error");
      return;
    }

    if (validator.isEmpty(data.email)) {
      swal("error", "Email cannot be empty", "Error");
      return;
    }

    let formData = new FormData();
    formData.append("file", data.image);
    let imgUrl;
    const uploadRes = await httpPostData("/auth/image_upload", formData);
    if (uploadRes.code === 201) {
      imgUrl = uploadRes.data.imagePath;
    }
    const addedData = { ...data, type: "vendor", avatar: imgUrl };
    const newData = removeEmptyString(addedData);
    console.log(newData);
    const res = await httpPost("/auth/admin_create_vendor", newData);
    if (res.code === 201) {
      hideLoader();
      swal("Done", res.message, "success").then(() =>
        props.history.push("/vendor_management")
      );
    }
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setData({ ...data, image: pictureFiles[0] });
  };

  return (
    <Layout
      RouteUserLayout={props.history}
      page="product"
      activepage="keepOpenEvents"
    >
      <div style={{ borderRadius: "10px" }} id="category-wraper">
        <div className="center-category-form ">
          <h1>Create Vendor</h1>
          <form className="category-form" onSubmit={handleSubmit}>
            <div className="form-group row">
              <div className="col-sm-12 col-md-6">
                <label>
                  First Name <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, firstName: e.target.value })
                  }
                  name="firstName"
                  type="text"
                  className="form-control"
                  value={data.firstName}
                  placeholder="Enter first name"
                  required={true}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label>
                  Last Name <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, lastName: e.target.value })
                  }
                  name="lastName"
                  type="text"
                  className="form-control"
                  value={data.lastName}
                  placeholder="Enter last name"
                  required={true}
                />
              </div>
            </div>

            <div className="form-group">
              <label>
                Email <span className="impt-i">*</span>
              </label>
              <input
                onChange={(e) => setData({ ...data, email: e.target.value })}
                name="email"
                type="email"
                className="form-control"
                value={data.email}
                placeholder="Enter Email"
                required={true}
              />
            </div>

            <div className="form-group">
              <label>
                Mobile <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter mobile number"
                required={true}
                value={data.phoneNumber}
                onChange={(e) =>
                  setData({ ...data, phoneNumber: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>
                Address <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter mobile number"
                required={true}
                value={data.address}
                onChange={(e) => setData({ ...data, address: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label>
                Company Name <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter company name"
                required={true}
                value={data.companyName}
                onChange={(e) =>
                  setData({ ...data, companyName: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Select Category</label>
              <div className="form-check">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  required={true}
                  value={data.companyName}
                  onChange={(e) =>
                    setData({ ...data, companyName: e.target.value })
                  }
                />
                <label className="form-check-label">Fashion</label>
              </div>
              <div className="form-check">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  required={true}
                  value={data.companyName}
                  onChange={(e) =>
                    setData({ ...data, companyName: e.target.value })
                  }
                />
                <label className="form-check-label">Accessories</label>
              </div>
            </div>

            {/* <div className="form-group row">
              <div className="col-sm-12 col-md-6">
                <label>
                  Bank Name <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, bankName: e.target.value })
                  }
                  name="bankName"
                  type="text"
                  className="form-control"
                  value={data.bankName}
                  placeholder="Enter bank name"
                  required={true}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label>
                  Account Number <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, accountNumber: e.target.value })
                  }
                  name="accountNumber"
                  type="text"
                  className="form-control"
                  value={data.accountNumber}
                  placeholder="Enter account name"
                  required={true}
                />
              </div>
            </div> */}

            <div className="form-group">
              <label>Upload Compnay Logo</label>
              <ImageUploader
                withIcon={true}
                withPreview={true}
                buttonText="Choose images"
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif", "jpeg"]}
                maxFileSize={5242880}
                singleImage={true}
              />
            </div>

            <div className="w-100 text-center mt-5">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Create Vendor
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
