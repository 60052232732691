import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { VendorTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [getGroup, setGroup] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();

  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  // useEffect(() => {
  //   getAllGroups();
  //   if (getDeletDetails.reload_state === true) {
  //     getAllGroups();
  //   }
  // }, [getDeletDetails.reload_state === true]);

  const getAllGroups = async () => {
    const res = await "/";
    if (res.status === 200) {
      //hideLoader();
    }
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="disputes"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          {/* <div>
            <button onClick={() => props.history.push('/create_category')}>
              Create new vendor
            </button>
          </div> */}

          {/* <GroupsTable getGroup={getGroup}/> */}
          <VendorTable
            getCategory={null}
            GetEditDataModals={GetEditDataModals}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllGroups={getAllGroups}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
