import React, { useState } from "react";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../../helpers/formatDate";
import { SumUp, calculatePercentage } from "../../../helpers/sumUp";
import { Percentage } from "../../../helpers/ratio";

export default function TransationTable(props) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Publication Name", field: "publicationName" },
          { title: "Business Name", field: "businessName" },

          props.role === "analyst admin" ? {} : { title: "Total Gained", field: "Price" },
          { title: "Subscription Ratio (%)", field: "SubscriptionRatio" },
          props.role === "analyst admin" ? {} : { title: "Publisher Gained", field: "PubAmount" },
          props.role === "analyst admin" ? {} : { title: "TNS Gained", field: "tnsAmount" },
        ]}
        data={props.transactionReport?.map((data) => {
          return {
            businessName: `${data?.company?.businessName}`,
            publicationName: `${data?.name} `,

            Price: (
              <span style={props.role === "analyst admin" ? { display: "none" } : {}}>{`₦${SumUp(
                data?.transactions
              )}`}</span>
            ),
            vendorFee: data?.payout?.amount,
            SubscriptionRatio: `${data?.company?.percentageRatio} :   ${Number(100 - data?.company?.percentageRatio)}`,
            PubAmount: (
              <span style={props.role === "analyst admin" ? { display: "none" } : {}}>{`₦${data.totalPayout}`}</span>
            ),

            tnsAmount: (
              // <span
              //   style={
              //     props.role === 'analyst admin' ? { display: 'none' } : {}
              //   }
              // >{`₦${(
              //   SumUp(data?.transactions) - Number(data.totalPayout)
              // ).toFixed(2)}`}</span>
              <span style={props.role === "analyst admin" ? { display: "none" } : {}}>{`₦${calculatePercentage(
                100 - data?.company?.percentageRatio,
                data.totalPayout
              )}`}</span>
            ),
          };
        })}
        title="Transaction Report"
      />
    </div>
  );
}

// data: {,…}
// publications: [{id: "006924be-117a-4a66-8bf9-d872205f0a77", name: "OluwaLoniGlory", totalPayout: "1400.00",…},…]
// 0: {id: "006924be-117a-4a66-8bf9-d872205f0a77", name: "OluwaLoniGlory", totalPayout: "1400.00",…}
// company: {id: "41159f32-580e-47da-86cc-42e829c8dc57", businessName: "Value Hunters", percentageRatio: 70}
// id: "006924be-117a-4a66-8bf9-d872205f0a77"
// name: "OluwaLoniGlory"
// totalPayout: "1400.00"
// transactions: [{id: "17495f86-8174-496e-b78f-cb7412e7790e", amount: "50", paymentStatus: "successful"},…]
// 0: {id: "17495f86-8174-496e-b78f-cb7412e7790e", amount: "50", paymentStatus: "successful"}
// amount: "50"
// id: "17495f86-8174-496e-b78f-cb7412e7790e"
// paymentStatus: "successful"
