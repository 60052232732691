import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import ImageUploader from 'react-images-upload';
import Layout from '../../shared/Layout';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import { httpGet, httpPost } from '../../../action/http';
import removeEmptyString from '../../../utils/removeEmptyString';
import MoneyInput from '../../shared/MoneyInput';

const Create = (props) => {
  const [data, setData] = useState({});
  const [pictures, setPictures] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customShipping, setCustomShipping] = useState('');
  const [customInstallation, setCustomInstallation] = useState('');

  useEffect(() => {
    getCategory();
    getVendors();
  }, []);

  const getCategory = async () => {
    const res = await httpGet('/all_category');
    if (res.code === 200) {
      setCategories(res.data.categories);
    }
  };

  const getVendors = async () => {
    const res = await httpGet('/auth/all_vendors');
    if (res.code === 200) {
      setVendors(res.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validator.isEmpty(data.name)) {
      swal('error', 'Title cannot be empty', 'Error');
      return;
    }
    if (validator.isEmpty(data.description)) {
      swal('error', 'Description cannot be empty', 'Error');
      return;
    }

    if (validator.isEmpty(data.costPrice)) {
      swal('error', 'Price cannot be empty', 'Error');
      return;
    }

    if (validator.isEmpty(data.image)) {
      swal('error', 'Image cannot be empty', 'Error');
      return;
    }
    // upload
    let imgUrl;
    const file = new FormData();
    file.append('file', data.image);
    const uploadRes = await httpPost('/auth/image_upload', file);
    if (uploadRes.code === 200) {
      imgUrl = uploadRes.data.imagePath;
    }
    const addedData = { ...data, image: uploadRes.data.imagePath };
    const newData = removeEmptyString(addedData);
    const res = await httpPost(
      `/admin_create_product/${data.categoryId}`,
      newData
    );
    if (res.code === 201) {
      swal('Done', res.message, 'success').then(() =>
        props.history.push('/products')
      );
    }
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles[0], pictureDataURLs);
    setData({ ...data, image: pictureFiles[0] });
    // setPictures(pictures.concat(pictureFiles));
  };

  return (
    <Layout
      RouteUserLayout={props.history}
      page="product"
      activepage="keepOpenEvents"
    >
      <div style={{ borderRadius: '10px' }} id="category-wraper">
        <div className="center-category-form ">
          <h1>Create Product</h1>
          <form className="category-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Title <span className="impt-i">*</span>
              </label>
              <input
                onChange={(e) => setData({ ...data, name: e.target.value })}
                name="eventName"
                type="text"
                className="form-control"
                id="title"
                value={data.name}
                placeholder="Enter Title"
                required={true}
              />
            </div>

            <div className="form-group">
              <label>
                Description <span className="impt-i">*</span>
              </label>
              <textarea
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                name="description"
                className="form-control"
                id="Presenter"
                placeholder="About Category"
                required
              />
            </div>

            <div className="form-group">
              <label>
                Price <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter the amount to add to the product price in this category"
                required={true}
                value={data.costPrice}
                onChange={(e) =>
                  setData({ ...data, costPrice: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Category</label>
              <select
                name=""
                id=""
                className="form-control"
                value={data.categoryId}
                onChange={(e) =>
                  setData({ ...data, categoryId: e.target.value })
                }
              >
                <option value=" " disabled selected>
                  Select
                </option>
                {categories.length
                  ? categories.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    ))
                  : ''}
              </select>
            </div>

            <div className="form-group">
              <label>Product Image</label>
              <ImageUploader
                withIcon={true}
                withPreview={true}
                buttonText="Choose images"
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                maxFileSize={5242880}
              />
            </div>

            <div className="form-group">
              <label>Vendor</label>
              <select
                name=""
                id=""
                className="form-control"
                value={data.vendorId}
                onChange={(e) => setData({ ...data, vendorId: e.target.value })}
              >
                <option value=" " disabled selected>
                  Select
                </option>
                {vendors.length
                  ? vendors.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.companyName}
                      </option>
                    ))
                  : ''}
              </select>
            </div>

            <div className="form-group">
              <label>Quantity in stock</label>
              <Input
                className="form-control"
                type="text"
                placeholder="Default to 1 if no number is inserted"
                value={data.quantityInStock}
                onChange={(e) =>
                  setData({ ...data, quantityInStock: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Shipping Fee</label>
              <MoneyInput
                className="form-control"
                placeholder="Default to 1 if no number is inserted"
                value={data.shippingFee}
                onChange={(e, value, floatValue) => {
                  console.log(value);
                  setData({ ...data, shippingFee: value });
                  setCustomShipping(e.target);
                }}
              />
            </div>

            <div className="form-group">
              <label>Installation Fee</label>
              <MoneyInput
                className="form-control"
                placeholder="Default to 1 if no number is inserted"
                value={data.installationFee}
                onChange={(e, value, floatValue) => {
                  console.log(value, e);
                  setData({ ...data, installationFee: value });
                  setCustomInstallation(e.target);
                }}
              />
            </div>

            <div className="w-100 text-center mt-5">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Create Product
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Create;
