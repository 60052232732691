import React from "react";
import back from "../../../assets/back.svg";
import "./index.css";

export default function Details({ data, setPnStatus, setData, pnStatus }) {
  console.log("heree>>", pnStatus);
  return (
    <div className="card p-3">
      {data?.category === "change of business name" && (
        <div className="publicNotice">
          <div className="nav">
            <div
              onClick={() => {
                setData({});
                setPnStatus("table");
              }}
              className="nav-back"
            >
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              {/* <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div> */}
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Change of business name</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Old business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).oldBussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">New business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).newBussinessName}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Business Address</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).businessAddress}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).bussinessTrustee1}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).bussinessTrustee2}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.category === "Business registration" && (
        <div className="publicNotice">
          <div className="nav">
            <div
              onClick={() => {
                setData({});
                setPnStatus("table");
              }}
              className="nav-back"
            >
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              {/* <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div> */}
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Business Registration</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).bussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">business Address</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).houseAddress}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).bussinessTrustee1}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).bussinessTrustee2}
                  </div>
                </div>
              </div>

              <div className="det-item">
                <div className="det-head">Business aim & Goals</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).aimsGoals}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.category === "Change of board of trustees" && (
        <div className="publicNotice">
          <div className="nav">
            <div
              onClick={() => {
                setData({});
                setPnStatus("table");
              }}
              className="nav-back"
            >
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              {/* <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div> */}
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Change of business trustees</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Address</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.houseAddress}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Old Business Trustees</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.bussinessTrustee1}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">New Business Trustees</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.bussinessTrustee2}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {data?.category === "Loss of document" && (
        <div className="publicNotice">
          <div className="nav">
            <div
              onClick={() => {
                setData({});
                setPnStatus("table");
              }}
              className="nav-back"
            >
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              {/* <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div> */}
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Loss of Document</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Full Name</div>
                  <div className="det-cont">
                    {`${JSON.parse(data?.informations).firstName} ${
                      JSON.parse(data?.informations).lastName
                    }`}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Document ID</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).documentId}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Type of Document</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).typeOfDoc}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Date of loss</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations).dateOfLoss}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations).declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
