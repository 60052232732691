import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import { httpPatch } from '../../../action/http';
import swal from 'sweetalert';
import DataTable from '../../shared/DataTable';
import FormatDate from '../../../helpers/formatDate';
import CapitalizeFirstLetter from '../../../helpers/capitalLizeFirstLetter';
import { hideLoader, showLoader } from '../../../utils/loader';
import ServiveCharge from "./ServiveCharge"

export const SubscriptionTable = ({ getSubscriptions, approveSubscription, getAllSubscription }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    showLoader();

    const res = await httpPatch(`/admin/archive_update_charge?id=${subscriptions.id}&serviceCharge=${subscriptions.serviceCharge}`);
    if (res.code === 200) {
      swal('Success', res.message, 'success');
      hideLoader();
      setLoading(false);
      setShowModal(!showModal);
      getAllSubscription();
    } else {
      swal('Error', res.message, 'error');
      setLoading(false);
      hideLoader();
    }
  };

  const data = getSubscriptions.map((data, index) => ({
    publicationName: data?.publication?.name,
    companyName: data?.publication?.company?.businessName,
    price: data?.price,
    charge:(
      <div className="d-flex">
        {`N${data?.serviceCharge}`}
        <i
          onClick={() => {
            setShowModal(!showModal);
            setSubscriptions({
              ...subscriptions,
              id: data.id,
              serviceCharge: data.serviceCharge,
            });
          }}
          class="fas fa-edit"
          style={{
            marginLeft: '10px',
          }}
        ></i>
      </div>
    ),
    status: data?.status === null ? 'Undefined' : CapitalizeFirstLetter(data?.status),
    createdAt: FormatDate(data.createdAt),
    action: (
      <div>
        <div>
          <select
            className="useractionSelect"
            name=""
            onChange={(e) => {
              approveSubscription(e.target.value, data.id);
            }}
          >
            <option value="">Select Action</option>
            {data.status === 'active' && <option value="inactive">Deactivate</option>}
            {data.status === 'pending' &&
              ['active', 'reject'].map((item) => <option value={item}>{item === 'active' ? 'Activate' : 'Reject'}</option>)}
            {data.status === 'inactive' && <option value="active">Activate</option>}
          </select>
        </div>
      </div>
    ),
  }));

  return (
    <div className="table-responsive">
      <DataTable
        title="Subscriptions"
        columns={[
          { title: 'Publication Name', field: 'publicationName' },
          { title: 'Company Name', field: 'companyName' },
          { title: 'Price', field: 'price' },
          { title: 'Service Charge', field: 'charge' },
          { title: 'Status', field: 'status' },
          { title: 'Applied On', field: 'createdAt' },
          { title: 'Action', field: 'action' },
        ]}
        data={data}
      />
      <ServiveCharge
        showModal={showModal}
        hideModal={setShowModal}
        handleSubmit={handleSubmit}
        subscriptions={subscriptions}
        setSubscriptions={setSubscriptions}
        loading={loading}
      />
    </div>
  );
};
