import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { UserCurrentState } from "../../../GlobalState/asyncState";
import { httpGet } from "../../../action/http";
import ReportCards from "./reportCards.jsx";
import TransactionReports from "./transactionReports";
import "./report.css";
import { hideLoader, showLoader } from "./../../../utils/loader";
const Index = (props) => {
  let [isVisible, setIsVisible] = useState({ isVisible: true });
  const [getUserDetails, setUserDetails] = useRecoilState(UserCurrentState);
  const [toggleTable, setToggleTable] = useState("TransactionReports");
  const [publications, setPublications] = useState([]);
  const [selectedPublication, setSelectedPublication] = useState({});
  const [selectedPublicationId, setSelectedPublicationId] = useState("");

  const [Reports, setReports] = useState([]);
  const [transactionReport, settransactionReport] = useState([]);
  const [userTransaction, setuserTransaction] = useState([]);
  const [vendorTransaction, setvendorTransaction] = useState([]);
  const [transationReportCard, setTransationReportCard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");
  const [filter, setFilter] = useState("today");
  const getUser = async () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    setRole(parseUserData?.role?.name);
  };
  useEffect(() => {
    getUser();
    getAllPublications();
  }, []);

  useEffect(() => {
    getAllReports();
  }, [selectedPublicationId, filter]);

  useEffect(() => {
    const selectedData = publications.find(
      (item) => item?.name === selectedPublication
    );
    setSelectedPublicationId(selectedData?.id);
  }, [selectedPublication]);

  const getAllReports = async () => {
    try {
      showLoader();
      const sales_report = await httpGet(
        `/admin/sales_report?${
          selectedPublicationId
            ? `filteredDate=custom&publicationId=${selectedPublicationId}`
            : `filteredDate=${filter}`
        }`
      );

      const transaction_report = await httpGet("/admin/transaction_report");
      if (sales_report.code == 200 || transaction_report.code === 200) {
        setTransationReportCard(transaction_report.data);
        settransactionReport(sales_report?.data?.publications);
        hideLoader();
        setLoading(false);
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const getAllPublications = async () => {
    let res = await httpGet(`/publications`);
    if (res) {
      if (res.code === 200) {
        setPublications(res.data.publications);
      }
    }
  };

  return (
    <React.Fragment>
      <Layout RouteUserLayout={props.history} page="reports">
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            {role == "analyst admin" ? (
              ""
            ) : (
              <ReportCards stats={transationReportCard} role={role} />
            )}

            <div className="reportsWrapSelect">
              <select onChange={(e) => setToggleTable(e.target.value)}>
                <option value="TransactionReports">Transaction Reports</option>
              </select>
              <select onChange={(e) => setSelectedPublication(e.target.value)}>
                <option value="TransactionReports">Select Publication</option>
                {publications?.map((data) => (
                  <option key={data?.id} value={data?.name}>
                    {data?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="tableReportsWrap">
              {toggleTable === "TransactionReports" ? (
                <div className="filterReportsTable">
                  <div className="filterreportsitgd">
                    <ul>
                      <li
                        onClick={() => setFilter("today")}
                        className={`${filter == "today" ? "activeFil" : ""}`}
                      >
                        Today
                      </li>
                      <li
                        onClick={() => setFilter("week")}
                        className={`${filter == "week" ? "activeFil" : ""}`}
                      >
                        Last 7 Days
                      </li>
                      <li
                        onClick={() => setFilter("month")}
                        className={`${filter == "month" ? "activeFil" : ""}`}
                      >
                        Last 30 Days
                      </li>

                      {/* <li>Custom</li> */}
                    </ul>
                  </div>

                  <TransactionReports
                    transactionReport={transactionReport}
                    role={role}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <br />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;
