import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Modal } from "react-responsive-modal";

function ViewCouponModal({ open, onClose, coupon, expireCoupon }) {
  const [copiedCode, setCopiedCode] = useState(false);
  const [copiedLink, setCopiedLink] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const couponLink = coupon
    ? `http://app.thenewspaperstand.com/coupon/redeem-card?code=${coupon.code}`
    : "";

  const onCopyCode = () => {
    setCopiedCode(true);
    setTimeout(() => {
      setCopiedCode(false);
    }, 2000);
  };

  const onCopyLink = () => {
    setCopiedLink(true);
    setTimeout(() => {
      setCopiedLink(false);
    }, 2000);
  };

  const onExpireCoupon = async (id) => {
    setIsProcessing(true);
    try {
      const res = await expireCoupon(id);
      if (res.success) {
        setIsProcessing(false);
        coupon.expired = true;
      }
    } catch (error) {}
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{
        modal: {
          borderRadius: "10px",
          border: "1px solid #EE7F0D",
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      {coupon ? (
        <div>
          <div>
            <p style={{ fontWeight: 600 }}>Status</p>
            <div
              className={`status ${
                coupon.expired ? "expired" : coupon.used ? "redeemed" : "active"
              }`}
              style={{
                background: coupon.used
                  ? "#0832f680"
                  : coupon.expired
                  ? "#f6040480"
                  : "#5afd9b33",
                padding: "10px 30px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {coupon.used
                  ? "Redeemed"
                  : coupon.expired
                  ? "Expired"
                  : "Active"}
              </p>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p style={{ fontWeight: 600 }}>Code</p>
            <div
              style={{
                background: "#C2CFE080",
                padding: "10px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {coupon.code}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <CopyToClipboard text={coupon.code} onCopy={onCopyCode}>
                <button
                  style={{
                    background: "#2BB509",
                    color: "#FFF",
                    borderRadius: "15px",
                    padding: "5px 10px",
                    fontWeight: 500,
                    //   float: "right",
                    marginTop: "3px",
                  }}
                >
                  {copiedCode ? "copied" : "copy"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <p style={{ fontWeight: 600 }}>Redeem Link</p>
            <div
              style={{
                background: "#C2CFE080",
                padding: "10px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {couponLink}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <CopyToClipboard text={couponLink} onCopy={onCopyLink}>
                <button
                  style={{
                    background: "#2BB509",
                    color: "#FFF",
                    borderRadius: "15px",
                    padding: "5px 10px",
                    fontWeight: 500,
                    //   float: "right",
                    marginTop: "3px",
                  }}
                >
                  {copiedLink ? "copied" : "copy"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <button
            disabled={isProcessing}
            onClick={() => onExpireCoupon(coupon.id)}
            style={{
              background: "#f6040480",
              padding: "10px",
              borderRadius: "10px",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {isProcessing ? "Loading..." : "Deactivate"}
          </button>
        </div>
      ) : null}
    </Modal>
  );
}

export default ViewCouponModal;
