import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Loader from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpGet, httpPost, httpPatch, httpPut } from "../../../action/http";
import MaleAvatar from "../../../assets/image/male.png";
import { ValidateInput, ValidateEmail } from "../../../helpers/validateInput";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Avatar from "react-avatar-edit";
import swal from "sweetalert";
import UploadImage from "../../../helpers/uploadImage";
import Layout from "../../shared/Layout";
import { hideLoader, showLoader } from "../../../utils/loader";
import { UserDataContext } from "../../../context/user";
export default function Home(props) {
  const { UserInfo, getUserProfile } = useContext(UserDataContext);
  const [EditProfilePicModalOpen, setEditProfilePicModalOpen] = useState(false);
  const OpenEditProfilePicModalModal = () => setEditProfilePicModalOpen(true);
  const setOpenEditProfilePicModalModal = () =>
    setEditProfilePicModalOpen(false);

  const [ChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const OpenChangePasswordModalModal = () => setChangePasswordModalOpen(true);
  const setOpenChangePasswordModalModal = () =>
    setChangePasswordModalOpen(false);

  const [userAccount, setUserAccount] = useState({
    email: "",
    companyName: "",
    rcNumber: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    avatar: "",
    repeatCurrentPassword: "",
    currentPassword: "",
    oldPassword: "",
  });

  const getUserInfo = () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    //setuserDataFromBackend(parseUserData);
    console.log("parseUserData>>", parseUserData);
  };

  const userDataInputes = async () => {
    let parseUserData = await UserInfo;
    //setuserDataFromBackend(parseUserData);
    console.log("parseUserData>>", parseUserData);
    setUserAccount({
      email: parseUserData?.email,
      companyName: parseUserData?.companyName,
      rcNumber: parseUserData?.rcNumber,
      firstName: parseUserData?.firstName,
      lastName: parseUserData?.lastName,
      phoneNumber: parseUserData?.phoneNumber,
      address: parseUserData?.address,
      avatar: parseUserData?.avatar,
      password: "",
      repeatCurrentPassword: "",
      currentPassword: "",
      oldPassword: "",
    });
  };
  useEffect(() => {
    userDataInputes();

    document.body.style.backgroundColor = "#F4F4F4";

    var elementsArray = document.getElementsByClassName("section-wrap");
    elementsArray[0].style.backgroundColor = "#F4F4F4";

    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, [UserInfo]);

  const onClose = () => {
    setPreview(preview);
  };
  const [imgSrc, setimgSrc] = useState(null);

  const [preview, setPreview] = useState(
    // userDataFromBackend.avatar === null
    //   ? MaleAvatar
    //   : userDataFromBackend.avatar
    MaleAvatar
  );
  const onCrop = (preview) => {
    console.log(imgSrc);
    setPreview(preview);
    dataURLtoFile(preview, `userAvatar`);
    setUserAccount({ ...userAccount, avatar: preview });
  };
  const [imageFile, setImageFile] = useState("");
  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const f = new File([u8arr], filename, { type: mime });
    setImageFile(f);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 921680) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    rcNumber: "",
    companyName: "",
    email: "",
    password: "",
    mobile: "",
    repeatCurrentPassword: "",
    currentPassword: "",
    oldPassword: "",
    username: "",
    firstName: "",
    lastName: "",
    tagLine: "",
    phoneNumber: "",
    address: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "rcNumber") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, rcNumber: testValid });
    }

    if (e.target.name === "companyName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, companyName: testValid });
    }

    if (e.target.name === "repeatCurrentPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, repeatCurrentPassword: testValid });
    }

    if (e.target.name === "currentPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, currentPassword: testValid });
    }

    if (e.target.name === "oldPassword") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, oldPassword: testValid });
    }

    if (e.target.name === "username") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, username: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }

    if (e.target.name === "firstName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
    }

    if (e.target.name === "lastName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, lastName: testValid });
    }

    if (e.target.name === "address") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, address: testValid });
    }

    if (e.target.name === "phoneNumber") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, phoneNumber: testValid });
    }

    if (e.target.name === "tagLine") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, tagLine: testValid });
    } else {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
    }
    console.log(userAccount);
  };

  const UpdateUserAvatar = async () => {
    if (imageFile === "") {
      NotificationManager.error("Please Upload An Image");
      return;
    }
    showLoader();
    const im = await UploadImage(imageFile);
    const data = {
      avatar: im.imagePath,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      phoneNumber: userAccount.phoneNumber,
    };
    let res = await httpPatch(`/auth/edit_profile`, data);
    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      getUserProfile();
      hideLoader();
      NotificationManager.success("Profile Image Updated Successfully.");
      setOpenEditProfilePicModalModal();
    }
  };

  const updateUserProfile = async () => {
    // if (errorInput.com) {

    // }
    let data = {
      // email: userAccount.email,
      // companyName: userAccount.companyName,
      // rcNumber: userAccount.rcNumber,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      phoneNumber: userAccount.phoneNumber,
      // address: userAccount.address,
    };
    showLoader();
    let res = await httpPatch(`/auth/edit_profile`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      getUserProfile();
      hideLoader();
      NotificationManager.success("Profile details updated successfully");
      console.log(">>>user", res);
    }
  };
  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
    oldPassword: "",
  });
  const handleUserPasswordInputChange = async (e) => {
    setUserPassword({
      ...userPassword,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassword = async () => {
    if (userPassword.confirmNewPassword !== userPassword.newPassword) {
      return swal({
        title: "Opps!",
        text: "Passwords do not match!",
        icon: "warning",
        dangerMode: true,
      });
    }
    const data = {
      newPassword: userPassword.newPassword,
      currentPassword: userPassword.oldPassword,
    };
    showLoader();
    let res = await httpPatch(`/auth/change_password`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        return NotificationManager.error(res.er.message);
      }
      setUserPassword({
        newPassword: "",
        confirmNewPassword: "",
        oldPassword: "",
      });
      hideLoader();
      setOpenChangePasswordModalModal();
      swal("Yepp", "Password updated  successfully.");
      console.log("user>>>>", res);
    }
  };

  const [Showloading, setLoading] = useState(false);
  if (Showloading) {
    return (
      <div className="row h-100 justify-content-center align-items-center app-spinner">
        <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
      </div>
    );
  } else {
    return (
      <Layout
        RouteUserLayout={props.history}
        page="settings"
        activepage="keepOpenGroup"
      >
        <React.Fragment>
          <div className="section-wrap">
            <div className="user-profile-layout">
              <div className="user-profile-card-one">
                <div className="user-profilecard-inner">
                  <div className="user-avatar-circle">
                    <img
                      src={`${
                        userAccount.avatar === null
                          ? MaleAvatar
                          : `${userAccount.avatar}`
                      }`}
                      alt=""
                    />
                  </div>
                  <div className="username-profile"></div>

                  <div className="profile-pic-chaageImage">
                    <button onClick={OpenEditProfilePicModalModal}>
                      Change Profile Pic
                    </button>
                  </div>
                </div>
              </div>
              <div className="user-profile-card-two">
                <div className="user-profile-card-two-inner-one">
                  <h1 className="userProfile-input-header">Account data</h1>

                  <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.firstName === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      First Name
                    </p>
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={userAccount.firstName}
                    />
                    <span
                      style={
                        errorInput.firstName === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.firstName}
                    </span>
                  </div>

                  <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.lastName === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      Last Name
                    </p>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={userAccount.lastName}
                    />
                    <span
                      style={
                        errorInput.lastName === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.lastName}
                    </span>
                  </div>

                  {/* <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.companyName === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      Company Name
                    </p>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={userAccount.companyName}
                    />
                    <span
                      style={
                        errorInput.companyName === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.companyName}
                    </span>
                  </div> */}

                  <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.email === "" ? "" : "move-label-up-profile"
                      }`}
                    >
                      Email
                    </p>
                    <input
                      disabled
                      type="text"
                      name=""
                      onChange={handleChange}
                      value={userAccount.email}
                    />
                    <span
                      style={
                        errorInput.email === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.email}
                    </span>
                  </div>

                  {/* <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.rcNumber === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      RC Number
                    </p>
                    <input
                      type="text"
                      name="rcNumber"
                      onChange={handleChange}
                      value={userAccount.rcNumber}
                    />
                    <span
                      style={
                        errorInput.rcNumber === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.rcNumber}
                    </span>
                  </div>

                  <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.address === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      Address
                    </p>
                    <input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      value={userAccount.address}
                    />
                    <span
                      style={
                        errorInput.address === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.address}
                    </span>
                  </div> */}

                  <div className="profileinput-box">
                    <p
                      className={`${
                        userAccount.phoneNumber === ""
                          ? ""
                          : "move-label-up-profile"
                      }`}
                    >
                      Phone Number
                    </p>
                    <input
                      type="text"
                      name="phoneNumber"
                      onChange={handleChange}
                      value={userAccount.phoneNumber}
                    />
                    <span
                      style={
                        errorInput.phoneNumber === "Looks Good!"
                          ? { color: "green", marginTop: "-8px" }
                          : {}
                      }
                      className="AppError appErrorProfil"
                    >
                      {errorInput.phoneNumber}
                    </span>
                  </div>

                  <div className="settingsActions">
                    <button
                      onClick={updateUserProfile}
                      className="editProfilebtn"
                    >
                      Save Changes
                    </button>

                    <button
                      style={{
                        marginLeft: "10px",
                        background: "none",
                        border: "1px solid orange",
                        color: "orange",
                      }}
                      onClick={OpenChangePasswordModalModal}
                      className="editProfilebtn"
                    >
                      Change Login Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={EditProfilePicModalOpen}
            onClose={setOpenEditProfilePicModalModal}
          >
            <div className="inner-modal-wrap">
              <div className="avatarProfileImageCrop">
                {" "}
                <Avatar
                  width={400}
                  height={295}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={imgSrc}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={`${
                    userAccount.avatar === null
                      ? MaleAvatar
                      : `${userAccount.avatar}`
                  }`}
                  alt="Preview"
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <button
                  style={{ marginRight: "25px" }}
                  onClick={UpdateUserAvatar}
                  className="editProfilebtn"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            open={ChangePasswordModalOpen}
            onClose={setOpenChangePasswordModalModal}
          >
            <div className="inner-modal-wrap">
              <form className="changeUserPassword">
                <div className="updateUserPaswordTitle">
                  <h1>Update Password</h1>
                </div>
                <div className="changePassinputWrap">
                  <label>Old Password</label>
                  <input
                    name="oldPassword"
                    onChange={handleUserPasswordInputChange}
                    value={userPassword.oldPassword}
                    type="password"
                    placeholder=""
                  />
                </div>

                <div className="changePassinputWrap">
                  <label>New Password</label>
                  <input
                    name="newPassword"
                    onChange={handleUserPasswordInputChange}
                    value={userPassword.newPassword}
                    type="password"
                    placeholder=""
                  />
                </div>

                <div className="changePassinputWrap">
                  <label>Confirm New Password</label>
                  <input
                    name="confirmNewPassword"
                    onChange={handleUserPasswordInputChange}
                    value={userPassword.confirmNewPassword}
                    type="password"
                    placeholder=""
                  />
                </div>
              </form>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginBottom: "15px",
                }}
              >
                <button
                  style={{ marginRight: "25px" }}
                  onClick={resetPassword}
                  className="editProfilebtn"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      </Layout>
    );
  }
}
