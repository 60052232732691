import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import swal from "sweetalert";
import Layout from "../../shared/Layout";
import { TransactionTable } from "./Table";
import { ConfirmModal } from "../../shared/Modal/ConfirmModal";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
// import { UserDataContext } from "./../../../context/user";
import { hideLoader, showLoader } from "../../../utils/loader";
//import CreateGroupModal from '../Modals/createGroup';

const Index = (props) => {
  const [transactions, setTransactions] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let [modalEdit, setmodalEdit] = useState();
  let [modalType, setmodalType] = useState("create");
  let [GroupId, setGroupId] = useState();
  const [role, setRole] = useState("");
  const GetEditDataModals = (type, data) => {
    setmodalEdit(data);
    setmodalType(type);
    setGroupId(data.id);
  };

  const getUser = async () => {
    let userData = localStorage.getItem("user");
    let parseUserData = JSON.parse(userData);
    setRole(parseUserData?.role?.name);
  };

  useEffect(() => {
    getAllTransactions();
    getUser();
    if (getDeletDetails.reload_state === true) {
      getAllTransactions();
    }
  }, [getDeletDetails.reload_state === true]);

  const getAllTransactions = async () => {
    try {
      const res = await httpGet("/admin/transaction_history");
      if (res.code === 200) {
        setTransactions(res.data.paymentHistory);
      }
    } catch (error) {
      return;
    }
  };

  const handleRefund = async (reference) => {
    const testEmail = "abideenayinde7@gmail.com";
    swal("Are you sure you want to refund?", {
      dangerMode: true,
      buttons: true,
    }).then(async (willRefund) => {
      if (willRefund) {
        showLoader();
        const res = await httpPost(
          `/admin/trans/initiate_refund?reference=${reference}&testEmail=${testEmail}`
        );
        if (res.code === 200) {
          hideLoader();
          swal({
            text: "Please input the OTP that has been sent to your mail to confirm refund",
            content: "input",
            button: {
              text: "Submit",
              closeModal: false,
            },
          }).then(async (otp) => {
            if (!otp) throw null;
            const postData = {
              code: otp,
              reference,
            };
            const res = await httpPost("/admin/trans/refund", postData);
            if (res.code === 200) {
              swal("Successfull", res.message, "success");
            } else {
              swal("Failed", res.message, "error");
            }
          });
        } else {
          hideLoader();
          swal("Failed", res.message, "error");
          swal.stopLoading();
          swal.close();
        }
      }
    });
  };

  return (
    <div>
      <Layout
        RouteUserLayout={props.history}
        page="transactions"
        activepage="keepOpenGroup"
      >
        <div className="table-wrap">
          <TransactionTable
            getTransactions={transactions || []}
            GetEditDataModals={GetEditDataModals}
            role={role}
            handleRefund={handleRefund}
          />
        </div>
      </Layout>
      <ConfirmModal />
      {/* <CreateGroupModal
        modalEdit={modalEdit}
        getAllTransactions={getAllTransactions}
        modalType={modalType}
        setmodalType={setmodalType}
        GroupId={GroupId}
      /> */}
    </div>
  );
};

export default Index;
