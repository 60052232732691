const logout = () => {
  localStorage.removeItem('token');
  window.location = '/';
};

const Logout = () => {
  return logout();
};

export default Logout;
