import React, { useEffect, useState } from 'react';
import Icon from './location.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { httpPatch } from '../../../action/http';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';

export default function VendorAccountDetails({ userAccountDet }) {
  const [open, setOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState({
    id: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
  });
  const [role, setRole] = useState('');
  const [loader, setLoader] = useState(false);

  const updateAccountDetails = async () => {
    setLoader(true);
    let res = await httpPatch(`/auth/edit_company`, accountDetails);

    if (res?.success) {
      swal('Success', 'Acount Details Updated!', 'success');
      setLoader(false);
    } else {
      swal('error', 'Something went wrong', 'error');
      setLoader(false);
    }
  };
  useEffect(() => {
    setAccountDetails({
      id: userAccountDet?.id,
      accountName: userAccountDet?.accountName,
      accountNumber: userAccountDet?.accountNumber,
      bankName: userAccountDet?.bankName,
    });
    let userData = localStorage.getItem('user');
    let parseUserData = JSON.parse(userData);
    setRole(parseUserData?.role?.name);
  }, []);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div style={{ marginTop: '25px' }}>
      <div>
        <div className="userAccountDetails">
          <div className="col1a">
            <span>Bank</span>
            <span>{userAccountDet?.bankName}</span>
          </div>

          <div className="col1a">
            <span>Account Details</span>
            <span>{`${userAccountDet?.accountNumber}, ${userAccountDet?.accountName}`}</span>
          </div>

          <div className="col1a">
            <span>Account Status</span>
            <span>{`${userAccountDet.status}`}</span>
          </div>
        </div>
        <div className="hrProfilePage"></div>
        <Modal show={open} onHide={onCloseModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Edit Acccount Details </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-5">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Acount Nmae</Form.Label>
                <Form.Control
                  onChange={(e) => setAccountDetails({ ...accountDetails, accountName: e.target.value })}
                  value={accountDetails.accountName}
                  type="text"
                  placeholder=""
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  onChange={(e) => setAccountDetails({ ...accountDetails, accountNumber: e.target.value })}
                  value={accountDetails.accountNumber}
                  type="text"
                  placeholder=""
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Bank</Form.Label>
                <Form.Control
                  onChange={(e) => setAccountDetails({ ...accountDetails, bankName: e.target.value })}
                  value={accountDetails.bankName}
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {loader ? <Spinner className="mt-5" animation="border" variant="secondary" /> : <Button onClick={updateAccountDetails}>Update</Button>}
          </Modal.Footer>
        </Modal>
        {role === 'super admin' ||
          (role === 'finance admin' && (
            <Button className="mt-5" variant="primary" onClick={onOpenModal}>
              Edit
            </Button>
          ))}
      </div>
    </div>
  );
}
