import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import validator from 'validator';
import Layout from '../../shared/Layout';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import ImageUploader from 'react-images-upload';
import { httpPost, httpGet, httpPostData, httpPut } from '../../../action/http';
import removeEmptyString from '../../../utils/removeEmptyString';
import { hideLoader, showLoader } from '../../../utils/loader';

export default function Create(props) {
  const [data, setData] = useState({});
  const [pictures, setPictures] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      showLoader();
      const res = await httpGet(`/auth/get_user/${props.match.params.id}`);
      if (res.code === 200) {
        setData(res.data);
        hideLoader();
        // setLoading(false);
      }
    } catch (error) {
      hideLoader();
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validator.isEmpty(data.firstName)) {
      swal('Oops!', 'First name cannot be empty', 'error');
      return;
    }
    if (validator.isEmpty(data.lastName)) {
      swal('Oops!', 'Last name cannot be empty', 'error');
      return;
    }

    if (validator.isEmpty(data.email)) {
      swal('Oops!', 'Email cannot be empty', 'error');
      return;
    }
    if (validator.isEmpty(data.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      return;
    }

    showLoader();

    let formData = new FormData();
    formData.append('file', data.image);
    let imgUrl;
    const uploadRes = await httpPostData('/auth/image_upload', formData);
    if (uploadRes.code === 201) {
      imgUrl = uploadRes.data.imagePath;
    }
    const addedData = { ...data, avatar: imgUrl };
    const newData = removeEmptyString(addedData);
    console.log(newData);

    const res = await httpPut(
      `/auth/edit_user/${props.match.params.id}`,
      newData
    );
    if (res.code === 200) {
      hideLoader();
      swal("Done", res.message, "success").then(() => props.history.goBack());
    }
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles[0], pictureDataURLs);
    setData({ ...data, image: pictureFiles[0] });
  };

  return (
    <Layout
      RouteUserLayout={props.history}
      page="user_mgt"
      activepage="keepOpenEvents"
    >
      <div style={{ borderRadius: '10px' }} id="category-wraper">
        <div className="center-category-form ">
          <h1>Edit User</h1>
          <form className="category-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Type <span className="impt-i">*</span>
              </label>
              <select
                name="type"
                className="form-control"
                value={data.type}
                onChange={(e) => setData({ ...data, type: e.target.value })}
              >
                <option value="select" disabled selected>
                  Select
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>

            <div className="form-group row">
              <div className="col-sm-12 col-md-6">
                <label>
                  First Name <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, firstName: e.target.value })
                  }
                  name="firstName"
                  type="text"
                  className="form-control"
                  value={data.firstName}
                  placeholder="Enter first name"
                  required={true}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label>
                  Last Name <span className="impt-i">*</span>
                </label>
                <input
                  onChange={(e) =>
                    setData({ ...data, lastName: e.target.value })
                  }
                  name="lastName"
                  type="text"
                  className="form-control"
                  value={data.lastName}
                  placeholder="Enter last name"
                  required={true}
                />
              </div>
            </div>

            <div className="form-group">
              <label>
                Email <span className="impt-i">*</span>
              </label>
              <input
                onChange={(e) => setData({ ...data, email: e.target.value })}
                name="email"
                type="email"
                className="form-control"
                value={data.email}
                placeholder="Enter Email"
                required={true}
              />
            </div>

            <div className="form-group">
              <label>
                Mobile <span className="impt-i">*</span>
              </label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter mobile number"
                required={true}
                value={data.phoneNumber}
                onChange={(e) =>
                  setData({ ...data, phoneNumber: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>Upload Image</label>
              <br />
              <label>Previous Image</label>
              <br />

              <img
                style={{ width: '70px', height: '70px' }}
                src={data.avatar}
                alt=""
              />
              <ImageUploader
                withIcon={true}
                withPreview={true}
                buttonText="Choose images"
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                maxFileSize={5242880}
                singleImage={true}
                defaultImage={data.avatar}
              />
            </div>

            <div className="w-100 text-center mt-5">
              <Button
                type="submit"
                onClick={handleSubmit}
                className="button-primary"
              >
                Edit Account
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
