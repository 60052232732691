import React, { Component, useEffect, useState } from 'react';
import { httpGet, httpPost } from '../../../action/http';
import './index.css';

const Index = () => {
  const [verified, setVerified] = useState(5);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('token');
    handleVerify(foo);
  }, []);

  const handleVerify = async (foo) => {
    try {
      const res = await httpPost(`/auth/user/verify/${foo}`);
      console.log(res);

      if (res.code === 403) {
        setVerified(2);
        setMessage(res.message);
      }

      if (res.code === 200) {
        setVerified(1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setVerified(0);
    }
  };

  return (
    <div
      className="page-has-left-panels page-has-right-panels notfound-body"
      id="main-panel-container"
    >
      <div className="container">
        <section className="notfound-section">
          <div className="container mt-5">
            <div className="page-error">
              <div className="page-inner">
                {verified === 1 ? (
                  <h2>Email Verification Successful</h2>
                ) : verified === 2 ? (
                  <h2>{message}</h2>
                ) : verified === 0 ? (
                  <h2>Problem occured, Please refresh page.</h2>
                ) : (
                  <h2>Loading..</h2>
                )}
                {/* <div className="notfound-description">
              Click the button below to login
            </div> */}
                <div className="page-search">
                  <div className="mt-3">
                    {/* <a href="/" className="btn btn-verify">
                      Login
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Index;
