import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import moment from "moment";
export const MessageTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const bodyRow = () => {
    const body = props.getMessages.map((data, index) => ({
      to: data.type === "message_users" ? "Users" : "Vendors",
      description: data.message,
      date: moment(data.createdAt).format("DD/MM/YYYY"),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Sent To (filterable)",
        prop: "to",
        sortable: true,
        filterable: true,
      },

      { title: "Description", prop: "description" },

      { title: "Date", prop: "date" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
