import React from "react";
import Table from "../../shared/CustomTable";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";

export const WithdrawRequestsTable = (props) => {
  const bodyRow = () => {
    const body = props.getRequests.map((data, index) => ({
      email: data.customerEmail,
      amount: data.amount,
      bank: data?.bank,
      currency: data.currency,
      company: data.companyId,
      status: CapitalizeFirstLetter(data.paymentStatus),
      action: (
        <button
          onClick={() =>
            props.confirmAction({
              amount: data.amount,
              currency: data.currency,
              reference: data.reference,
              companyId: data.companyId,
            })
          }
          style={{
            background: "#E47E15",
            color: "#fff",
            borderRadius: "3px",
            padding: "4px 10px",
            fontWeight: "500",
          }}
        >
          Confirm
        </button>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Email (filterable)",
        prop: "email",
        sortable: true,
        filterable: true,
      },
      { title: "Amount", prop: "amount", sortable: true, filterable: true },

      { title: "Bank", prop: "bank", sortable: true, filterable: true },

      { title: "Currency", prop: "currency" },

      { title: "Company", prop: "company" },

      { title: "Status", prop: "status" },
      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
