import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import FormatDate from "../../../helpers/formatDate";
export const VendorTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = [];
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Title (filterable)",
        prop: "title",
        sortable: true,
        filterable: true,
      },

      { title: "Description", prop: "description" },

      { title: "Members", prop: "Members" },

      {
        title: "Open/Close (filterable)",
        prop: "closed",
        sortable: true,
        filterable: true,
      },

      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
