import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import { UserCurrentState } from "../../../GlobalState/asyncState";
import { Overview } from "./overview";
import OverviewStat from "./staticticsOverview";
import Statictics from "./statictics";
import "./index.css";
import { httpGet } from "../../../action/http";
import { hideLoader } from "../../../utils/loader";

const Index = (props) => {
  let [isVisible, setIsVisible] = useState({ isVisible: true });
  const [getUserDetails, setUserDetails] = useRecoilState(UserCurrentState);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = async () => {
    const res = await httpGet("/admin/dashboard_stats");
    if (res?.code === 200) {
      setStats(res?.data);
      hideLoader();
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Layout RouteUserLayout={props.history} page="dashboard">
        {loading ? (
          <div className="row h-100 justify-content-center align-items-center">
            <Loader type="TailSpin" color="#EF6C00" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <Overview stats={stats} />
            <div id="app">
              <OverviewStat stats={stats} />

              <Statictics stats={stats} />
            </div>
            <br />
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Index;
