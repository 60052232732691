import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import Table from '../../shared/CustomTable';
import FormatDate from '../../../helpers/formatDate';
import CapitalizeFirstLetter from '../../../helpers/capitalLizeFirstLetter';
export const VendorTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  let text_truncate = (str, length) => {
    return str.substring(0, length - 1) + (str.length > length ? '...' : '');
  };
  const bodyRow = () => {
    const body = props.getVendors
      .sort((a, b) => {
        return b.createdAt - a.createdAt;
      })
      .map((data, index) => ({
        businessName: (
          <a
            style={{
              textDecoration: 'underline',
              color: '#E47E15',
            }}
            href={`view_vendor/${data.id}`}
          >
            {data.businessName === null ? 'No data added' : data.businessName}
          </a>
        ),
        email: data?.companyOwner?.email,
        walletBalance1: !data?.wallet.length
          ? 'Account Not Verified'
          : data?.wallet[0]?.currency === 'NGN'
          ? `₦${data?.wallet[0]?.amount}`
          : `₦${data?.wallet[1]?.amount}`,
        walletBalance2: !data?.wallet.length
          ? 'Account Not Verified'
          : data?.wallet[0]?.currency === 'USD'
          ? `$${data?.wallet[0]?.amount || 'No dollar wallet'}`
          : `$${data?.wallet[1]?.amount || 'No dollar wallet'}`,
        address:
          data.address !== null
            ? text_truncate(data.address, 20)
            : 'Address not added',
        phone: data?.phone ? data?.phone : 'Phone number not added',
        country: data.country,
        rc: data?.cacNumber !== null ? data?.cacNumber : 'RC not added',
        status: CapitalizeFirstLetter(data.status),
        action: (
          <div>
            <select
              className='useractionSelect'
              name=''
              onChange={(e) => {
                props.comfirmAction(e.target.value, data?.id);
              }}
              id=''
            >
              <option value=''>Select Action</option>
              <option value='inactive'>Deactivate</option>
              <option value='active'>Activate</option>
            </select>
          </div>
        ),
      }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: 'Business Name (filterable)',
        prop: 'businessName',
        sortable: true,
        filterable: true,
      },
      {
        title: 'Email (filterable)',
        prop: 'email',
        sortable: true,
        filterable: true,
      },

      {
        title: 'RC Number (filterable)',
        prop: 'rc',
        sortable: true,
        filterable: true,
      },

      {
        title: 'Wallet Balance (filterable) NGN',
        prop: 'walletBalance1',
        sortable: true,
        filterable: true,
      },
      {
        title: 'Wallet Balance (filterable) USD',
        prop: 'walletBalance2',
        sortable: true,
        filterable: true,
      },

      { title: 'Address', prop: 'address', sortable: true, filterable: true },

      { title: 'Phone', prop: 'phone' },

      {
        title: 'Country (filterable)',
        prop: 'country',
        sortable: true,
        filterable: true,
      },
      { title: 'Status', prop: 'status' },

      { title: 'Action', prop: 'action' },
    ];
    return header;
  };

  return (
    <div className='table-responsivee'>
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
