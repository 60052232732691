import React, { useState } from "react";
import { Modal } from "reactstrap";
import Button from "../../shared/Button";
import "./style.css";

export default function WithdrawMoney(props) {
  const {
    showModal,
    hideModal,
    bankDetails,
    setData,
    data,
    handleWithdrawal,
    btnLoading,
    report,
    report: {
      payout,
      tnsProfit,
      subscriptionPayments,
      totalUserCurrentWalletBalance,
      totalVendorCurrentWalletBalance,
      fundWalletTransactions,
      withdrawalAmount,
    },
  } = props;
  const [isconfirm, setIsconfirm] = useState(false);
  return (
    <Modal
      centered
      toggle={() => {
        hideModal(false);
      }}
      isOpen={showModal}
    >
      <div className="account-modal">
        <div className="account-modal-head">
          <div className="with-head mb-1">
            <div className="with-head-top">Your wallet balance</div>
            <div
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "34px",
                lineHeight: "41px",
                color: "#333333",
              }}
            >
              {report
                ? +tnsProfit +
                  +totalVendorCurrentWalletBalance -
                  withdrawalAmount
                : 0}
            </div>
          </div>
          <div
            className="head-x"
            style={{
              marginTop: "-58px",
            }}
            onClick={() => hideModal(false)}
          >
            x
          </div>
        </div>

        <div className="account-input" style={{}}>
          <div className="acc-in-title">Currency</div>
          <select
            name=""
            id=""
            className="account-input"
            onChange={(e) => setData({ ...data, currency: e.target.value })}
          >
            <option>select currency</option>
            <option value="NGN">Naira</option>
          </select>
        </div>
        <div className="account-input">
          <div className="acc-in-title">
            Enter the amount you will like to withdraw
          </div>
          <input
            type="text"
            data={data}
            placeholder="e.g 40,000"
            onChange={(e) => setData({ ...data, amount: e.target.value })}
          />
        </div>

        <div className="with-detail">
          <div className="">Your withdrawal will be sent to:</div>
          <div className="w-100">
            <b>
              {`${bankDetails?.accountName}  ${bankDetails?.accountNumber}  ${bankDetails?.bankName}`.toUpperCase()}
            </b>
          </div>
        </div>
        <div className="with-check">
          <input
            type="checkbox"
            name=""
            id=""
            onChange={() => setIsconfirm(!isconfirm)}
            checked={isconfirm}
          />
          <div>Confirm</div>
        </div>
        <Button
          onClick={handleWithdrawal}
          className=""
          content="Withdraw"
          disabled={!isconfirm}
          loading={btnLoading}
        />
      </div>
    </Modal>
  );
}
