import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import currencyFormatter from "currency-formatter";
import FormatDate from "../../../helpers/formatDate";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";
export const TransactionTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getTransactions.map((data, index) => ({
      reference: data.reference,
      product: data.message,
      amount: (
        <span style={props.role === "analyst admin" ? { display: "none" } : {}}>
          {currencyFormatter.format(data.amount, { code: "NGN" })}
        </span>
      ),
      customer: data.customerEmail,
      status: CapitalizeFirstLetter(data.paymentStatus),
      date: FormatDate(data.createdAt),
      action:
        data.paymentStatus === "successful" &&
        data.bank === "card" &&
        data.message === "Subscription payment" ? (
          <button
            onClick={() => props.handleRefund(data?.reference)}
            style={{
              background: "#E47E15",
              color: "#fff",
              borderRadius: "3px",
              padding: "4px 10px",
            }}
          >
            Refund
          </button>
        ) : (
          ""
        ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Transaction Id (filterable)",
        prop: "reference",
        sortable: true,
        filterable: true,
      },

      { title: "About", prop: "product" },

      props.role === "analyst admin" ? {} : { title: "Amount", prop: "amount" },

      {
        title: "Customer (filterable)",
        prop: "customer",
        sortable: true,
        filterable: true,
      },

      { title: "Status", prop: "status" },

      { title: "Date", prop: "date" },
      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
