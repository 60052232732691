import React, { useState } from "react";
import icons from "../table/icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import dateFormater from "./../../../helpers/formatDate";
import Switch from "react-switch";
const totalPurchase = (items) => {
  //console.log(items);
  return items.reduce((a, b) => a + (Number(b.amount) || 0), 0);
};

const Udata = [
  {
    editionName: "Food And Nuts",
    action: true,
    status: "Approved",
  },
  {
    editionName: "Food And Nuts",
    action: true,
    status: "Approved",
  },
  {
    editionName: "Food And Nuts",
    action: true,
    status: "Approved",
  },
];

const handleeditionNameChange = () => {
  alert("hello");
};

export default function UserTransationTable({ editions, PublicationName }) {
  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Edition Name", field: "editionName" },
          // { title: "Action", field: "action" },
          { title: "Status ", field: "Status" },
          { title: "Date ", field: "date" },
        ]}
        data={editions?.map((data) => {
          return {
            editionImage: (
              <img
                style={{ width: "100px", height: "100px" }}
                src={editions.image}
              />
            ),
            editionName: data?.editionName,
            // action: (
            //   <Switch
            //     checked={data.action}
            //     onColor="#14274E"
            //     uncheckedIcon={data.action}
            //     checkedIcon={data.action}
            //     height={20}
            //     width={40}
            //     handleDiameter={17}
            //     onChange={handleeditionNameChange}
            //   />
            // ),
            Status: data?.status,
            date: dateFormater(data.createdAt),
          };
        })}
        title={`${PublicationName} Editions`}
      />
    </div>
  );
}
