import React, { useEffect } from "react";
import Icon from "./location.png";
import CategoriesTable from "./agentDriverTable";

export default function CoverageArea({ vendorTeam }) {
  useEffect(() => {
    console.log("team>>>", vendorTeam);
  }, [vendorTeam]);
  return (
    <div style={{ marginTop: "25px" }}>
      <CategoriesTable vendorTeam={vendorTeam} />
    </div>
  );
}
