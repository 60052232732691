import React from 'react';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const ButtonComponent = ({
  role,
  onClick,
  type,
  disabled,
  className,
  children,
  block,
  outline,
  content,
  size,
  loading,
  variant,
}) => (
  <Button
    type={type}
    role={role}
    disabled={disabled}
    onClick={onClick}
    className={className}
    block={block}
    outline={outline}
  >
    {loading ? (
      <Spinner
        as="span"
        animation="border"
        size={size || 'md'}
        role="status"
        aria-hidden="true"
        variant={variant || 'light'}
      />
    ) : (
      content || children
    )}
  </Button>
);

export default ButtonComponent;
