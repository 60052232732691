import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import Table from "../../shared/CustomTable";
import FormatDate from "../../../helpers/formatDate";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";
export const UserTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getUsers.map((data, index) => ({
      email: data.email,
      name: `${data.lastName} ${data.firstName}`,
      country: data.country,
      userType: CapitalizeFirstLetter(data.userType),
      status: CapitalizeFirstLetter(data.status),
      action: (
        <div>
          <div>
            <select
              className="useractionSelect"
              name=""
              onChange={(e) => {
                props.comfirmAction(e.target.value, data.id);
              }}
              id=""
            >
              <option value="">Select Action</option>
              <option value="inactive">Deactivate</option>
              <option value="active">Activate</option>
            </select>
          </div>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: "Email (filterable)",
        prop: "email",
        sortable: true,
        filterable: true,
      },

      { title: "Name", prop: "name", sortable: true, filterable: true },

      { title: "Country", prop: "country" },

      {
        title: "Role (filterable)",
        prop: "userType",
        sortable: true,
        filterable: true,
      },
      { title: "Status", prop: "status" },
      { title: "Action", prop: "action" },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
