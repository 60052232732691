import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { delet_edit_Handle } from '../../../GlobalState/localState';
import Table from '../../shared/CustomTable';

export const PayoutTable = (props) => {
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);

  const bodyRow = () => {
    const body = props.getPayouts.map((data, index) => ({
      ref: data.recipentCode,
      vendor: `${data.vendor.lastName} ${data.vendor.firstName}`,
      amount: data.amount,
      date: data.createdAt,
      action: (
        <div>
          <button
            className="btn btn button-primary text-white"
            onClick={() => props.initiatePayment(data.id)}
          >
            Pay
          </button>
        </div>
      ),
    }));
    return body;
  };

  const header = () => {
    const header = [
      {
        title: 'Payout Ref (filterable)',
        prop: 'ref',
        sortable: true,
        filterable: true,
      },

      { title: 'Vendor', prop: 'vendor' },

      { title: 'Amount', prop: 'amount' },

      {
        title: 'Date (filterable)',
        prop: 'date',
        sortable: true,
        filterable: true,
      },

      { title: 'Action', prop: 'action' },
    ];
    return header;
  };

  return (
    <div className="table-responsivee">
      <Table
        body={bodyRow}
        head={header}
        rowsPerPage={10}
        rowsPerPageOption={[10, 15, 20, 25]}
      />
    </div>
  );
};
