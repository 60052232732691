import React, { useState, useEffect } from "react";
import Layout from "../shared/Layout";
import UserMessages from "./userMessages";
import "./index.css";
import { Modal } from "react-responsive-modal";
import giftIcon from "../../assets/image/giftIcon.png";
import { AppSocket, notifications } from "../../action/socket";
import { httpDelete, httpGet, httpPatch, httpPost } from "../../action/http";
import { showLoader, hideLoader } from "../../utils/loader";
import VendorMsg from "./vendorMsg";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
const Index = ({ history }) => {
  const [activeTab, setActiveTab] = useState("userMsg");
  const [send, setsend] = useState("");
  const [openUserModal, setopenUserModal] = useState(false);
  const [Publications, setPublications] = useState([]);
  const [userNotification, setuserNotification] = useState([]);
  const [vendorNotification, setvendorNotification] = useState([]);
  const [progress, getProgress] = useState("");

  const [msg, setMsg] = useState({
    publicationId: null,
    title: "",
    body: "",
    messageRecipient: send,
    type: "default",
    startDate: new Date(),
    endDate: new Date(),
    featuredImage: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [stateNotification, setStateNotification] = useState([]);
  const [Mode, setMode] = useState("create");
  const [EditDetails, setEditDetails] = useState("");
  useEffect(() => {
    // AppSocket()
    // AppSocket.io.on("connect", () => {
    //   console.log("connected to server");
    // });
    AppSocket.createConnection();
  }, []);

  useEffect(() => {
    setStateNotification(notifications);
  }, [notifications]);

  useEffect(() => {
    getAllPublication();
    adminNotifications();
  }, []);

  const toggleUserModal = () => {
    setopenUserModal(!openUserModal);
  };

  const handleWhoToSend = (e) => {
    setsend(e.target.value);
    toggleUserModal();
  };

  const getAllPublication = async () => {
    const res = await httpGet("/publications");
    if (res) {
      if (res.er) {
        return;
      }
      setPublications(res?.data?.publications);
    }
  };

  const postImg = async (data) => {
    showLoader();
    let formData = new FormData();
    formData.append("file", data);

    const res = await httpPost(
      "/auth/profile_image_upload",
      formData,
      getProgress
    );
    if (res) {
      if (res.er) {
        hideLoader();
        return;
      }
      hideLoader();
      setMsg({ ...msg, featuredImage: res.data.imagePath });
    }
  };

  const handleSubmit = async () => {
    const data = {
      publicationId: msg.publicationId,
      title: msg.title,
      body: msg.body,
      messageRecipient: send,
      type: "default",
      startDate: msg.startDate,
      endDate: msg.endDate,
      featuredImage: msg.featuredImage,
    };
    AppSocket.sendRequest("admin_send_notifications", data);
    adminNotifications();
    setsend("");
    setMsg({
      publicationId: null,
      title: "",
      body: "",
      messageRecipient: send,
      type: "default",
      startDate: null,
      endDate: null,
      featuredImage: "",
    });
    // toggleUserModal();
  };

  const editMsgSubmit = async () => {
    const data = {
      publicationId: msg.publicationId,
      title: msg.title,
      body: msg.body,
      messageRecipient: send,
      type: "default",
      startDate: msg.startDate,
      endDate: msg.endDate,
      featuredImage: msg.featuredImage,
    };
    showLoader();
    const res = await httpPatch(`/notification/update/${EditDetails.id}`, data);
    if (res) {
      hideLoader();
      if (res.code == 200) {
        swal("Success", "Message Updates Successfully.", "success");
      }
    }
    adminNotifications();
    setsend("");
    setMsg({
      publicationId: null,
      title: "",
      body: "",
      messageRecipient: send,
      type: "default",
      startDate: null,
      endDate: null,
      featuredImage: "",
    });
    toggleUserModal();
  };

  const adminNotifications = async () => {
    showLoader();
    const res = await httpGet("/admin/notifications");
    if (res) {
      hideLoader();
      if (res.er) {
        hideLoader();
        return;
      }
      hideLoader();
      const getVendorN = res?.data?.filter((d) => {
        return d.messageRecipient == "vendor";
      });

      const getUserN = res?.data?.filter((d) => {
        return d.messageRecipient == "user";
      });

      setuserNotification(getUserN);
      setvendorNotification(getVendorN);
    }
    hideLoader();
  };

  const deleteMsg = async (id) => {
    // return alert(id);
    showLoader();
    let res = await httpDelete(`/notification/delete/${id}`);
    if (res) {
      hideLoader();
      if (res.data.code == 200) {
        adminNotifications();
        swal("Success", "Message Deleted", "success");
      }
    }
  };

  const getEditData = (data, send) => {
    setsend(send);
    setMode("edit");
    setEditDetails(data);
    toggleUserModal();
    setMsg({
      ...msg,
      title: data.title,
      body: data.body,
      featuredImage: data.image,
      publicationId: data.publicationId,
    });
  };

  return (
    <Layout
      RouteUserLayout={history}
      page="messaging"
      activepage="keepOpenGroup"
    >
      <div className="sendMsgyfvSelect">
        <select name="" id="" onChange={handleWhoToSend} value={send}>
          <option value="">Message </option>
          <option value="user">User</option>
          <option value="vendor">Vendor</option>
        </select>
      </div>
      <div className="msg-page-tab">
        <ul>
          <li
            className={`${activeTab == "userMsg" ? "userMsgActiveTb" : ""}`}
            onClick={() => setActiveTab("userMsg")}
          >
            User Messages
          </li>
          <li
            className={`${activeTab == "VendorMsg" ? "userMsgActiveTb" : ""}`}
            onClick={() => setActiveTab("VendorMsg")}
          >
            Vendor Messages
          </li>
        </ul>
      </div>
      {activeTab == "userMsg" ? (
        <UserMessages
          userNotification={userNotification}
          deleteMsg={deleteMsg}
          getEditData={getEditData}
        />
      ) : (
        <VendorMsg userNotification={vendorNotification} />
      )}

      <Modal open={openUserModal} onClose={toggleUserModal}>
        <div className="inner-modal-wrap">
          <div className="send-user-msg-container">
            <div className="send-msg-conad">
              <div className="giftIconImg">
                <img src={giftIcon} alt="" />
              </div>
              <div className="msginfoCol1">
                <div className="msgsel4tn">
                  <label htmlFor="">Publication</label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setMsg({ ...msg, publicationId: e.target.value });
                    }}
                    value={msg.publicationId}
                  >
                    <option value="">Select Publication</option>
                    {Publications?.map((data) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </select>
                </div>

                <div className="msgsel4tn">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setMsg({ ...msg, title: e.target.value });
                    }}
                    value={msg.title}
                  />
                </div>
              </div>
            </div>
            <div className="msgbodymainkji5">
              <label htmlFor="">Body {progress}</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                onChange={(e) => {
                  setMsg({ ...msg, body: e.target.value });
                }}
                value={msg.body}
              ></textarea>
            </div>

            <div className="sendMsg">
              <input
                type="file"
                onChange={(e) => {
                  postImg(e.target.files[0]);
                }}
              />
              <div className="addimgsendmWrap">
                <div className="addimgsendm">
                  <span>Featured Image</span>
                  <button>Upload</button>
                </div>
              </div>
            </div>
            {msg.featuredImage ? (
              <div className="displayFileMsg">
                <img src={msg?.featuredImage} alt="" />
              </div>
            ) : (
              ""
            )}

            {send == "vendor" ? (
              ""
            ) : (
              <div className="sendimgtimer">
                <div className="timConIn">
                  <label htmlFor="">Start</label>

                  <DatePicker
                    selected={msg.startDate}
                    onChange={(date) => setMsg({ ...msg, startDate: date })}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                  />
                </div>

                <div className="timConIn">
                  <label htmlFor="">End</label>

                  <DatePicker
                    selected={msg.endDate}
                    onChange={(date) => setMsg({ ...msg, endDate: date })}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                  />
                </div>
              </div>
            )}

            <div className="sendmsgform">
              <button onClick={Mode == "edit" ? editMsgSubmit : handleSubmit}>
                {Mode == "edit" ? "Edit" : "Send"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Index;
