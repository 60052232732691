import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Layout from "../../shared/Layout";
import DataTable from "../../shared/DataTable";
import { delet_edit_Handle } from "../../../GlobalState/localState";
import { httpGet, httpPost } from "../../../action/http";
import FormatDate from "../../../helpers/formatDate";
import CapitalizeFirstLetter from "../../../helpers/capitalLizeFirstLetter";
import swal from "sweetalert";
import { hideLoader, showLoader } from "../../../utils/loader";

const Applications = (props) => {
  const [applications, setApplications] = useState([]);
  let [getDeletDetails, setDeletDetails] = useRecoilState(delet_edit_Handle);
  const [loading, setLoading] = useState(true);

  const getAllApplicants = async () => {
    try {
      const res = await httpGet("/admin/archive_applications");
      if (res.code === 200) {
        setApplications(res.data.archiveApplications);
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
  };

  const data = applications.map((data, index) => ({
    name: `${data?.user?.firstName} ${data?.user?.lastName} `,
    publishName: data?.publication?.name,
    reason: data?.reason,
    status:
      data?.status === null ? "Undefined" : CapitalizeFirstLetter(data?.status),
    dateRequested: (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {JSON.parse(data?.requestDates).map((date) => (
          <span>{`${date?.date},   `} </span>
        ))}
      </div>
    ),
    createdAt: FormatDate(data?.createdAt),
  }));

  useEffect(() => {
    getAllApplicants();
    if (getDeletDetails.reload_state === true) {
      getAllApplicants();
    }
  }, [getDeletDetails.reload_state === true]);

  return (
    <Layout
      RouteUserLayout={props.history}
      page="arcApplications"
      activepage="keepOpenGroup"
    >
      <div className="table-responsive">
        <DataTable
          title="Applications"
          columns={[
            { title: "Name", field: "name" },
            { title: "Publication Name", field: "publishName" },
            { title: "Date Requested", field: "dateRequested" },
            { title: "Status", field: "status" },
            { title: "Date Applied", field: "createdAt" },
          ]}
          data={data}
        />
      </div>
    </Layout>
  );
};

export default Applications;
